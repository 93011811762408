// .linkStyle {
//     color: white;
//     transition: 0.3s all;
//     margin-left: 5px;

//     &:hover {
//         transition: 0.3s all;
//     }
// }

.div_popupCovidForm{
    display: flex;
    justify-content: center;

    .popupCovidForm{
        position: fixed;
        bottom:15%;
        // right:0;
        width: 50%;
        background-color: #6a3f87e6;
        color: white;
        height: fit-content;
        overflow: auto;
        border: 2px solid #fff;
        padding: 4px;
        border-radius: 4px;
        text-align: center;
        overflow-x: hidden;
        font-size: 13px;
        z-index: 99;
        height: 60vh;

        .heading_covidPopUPForm{
            padding: 30px;
        }

        .heading_covidPopUPForm span{
            font-size: 25px;       
        }

        .MuiButtonBase-root{
            position: absolute;
            color: white;       
            right: 1px;
            top: 1px;
            padding: 5px;
        }

        .MuiSvgIcon-root{
            width: 2em!important;
            height: 1.5em!important;
        }

        .for_form{
            width: 70%;
            font-size: 20px;
            margin: 10px;
        }

        .for_form_dp{
            width: 72%;
            font-size: 21px;
            margin: 10px;
        }
        
        .for_social_icon{
           
            img{
                width:10%;
                height: 10%;
                box-sizing: border-box;
                text-align:center;
                overflow:hidden;
                padding: 5px;
            }
        }

        .signUpBtnCF {
            background-color: rgba(106, 63, 135, 1);
            color: white;
            border-radius: 4px;
            font-size: 40px;
            font-family: 'Anton', sans-serif;
            border: 2px solid white;
            margin-top: 20px;
            // padding: 5px;
            width: 31%;
        }

        .signUpBtnCF:hover {
            cursor: pointer;
            background-color: #D5661C;
        }
    }

}

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
    .div_popupCovidForm .popupCovidForm .heading_covidPopUPForm {
        padding: 58px;
    }

    .div_popupCovidForm .popupCovidForm {
        height: 50vh;
        bottom: 22%;

        .signUpBtnCF {
            width: 37%!important;
            font-size: 40px!important;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1022px) {
    .popupCovidForm{
        .signUpBtnCF {
            width: 47%!important;
            font-size: 40px!important;
        }
    }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
    .popupCovidForm{
        .signUpBtnCF {
            width: 50%!important;
            font-size: 40px!important;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 640px) {
    .popupCovidForm{
        .signUpBtnCF {
            width: 60%!important;
            font-size: 40px!important;
        }
    }
}

@media only screen and (min-width: 568px) and (max-width: 600px) {
    .popupCovidForm{
        .signUpBtnCF {
            font-size: 20px!important;
        }
    }
}

@media only screen and (max-width: 567px) {
    .popupCovidForm{
        width: 80%!important;

        .heading_covidPopUPForm{
            padding: 30px;
        }

        .heading_covidPopUPForm span{
            font-size: 25px;       
        }

        .signUpBtnCF {
            width: 34%!important;
            font-size: 20px!important;
        }
    }

    .div_popupCovidForm .popupCovidForm .for_social_icon img {
        width: 13%;
        height: 13%;
    }
}

@media only screen and (max-width: 320px) {
    .popupCovidForm{
        .signUpBtnCF {
            width: 39%!important;
            font-size: 20px!important;
            margin-left: 22px;
        }

        .container .btn {
            font-size: 20px;
        }
    }
}