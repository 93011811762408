@media only screen and (min-width: 769px) {
    .SignUp{
        padding-top: 11%;
        .headerImg{
            width: 18%!important;
            // padding-bottom: 20px;
        }
    }
  }
@media only screen and (min-width: 600px) and (max-width: 768px){
        .SignUp{
            padding-top: 55px;
            .headerImg{
                width: 30%!important;
                padding-bottom: 20px;
            }
        }
}
  @media only screen and (max-width: 600px) {
    .SignUp{
        padding-top: 40px;
        .headerImg{
            width: 37%!important;
            padding-bottom: 20px;
        }
    }
  }
  
.SignUp {
    .hiddenHeader{
        visibility: hidden;
        height: 0px;
        width: 0px;
        margin: 0px;
        padding: 0px;
    }
    background-image: url("../../assets/compressedimages/SignUp-BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}