.faqModule {
  width: 100%;
  background-image: url("../../assets/compressedimages/FAQ-BG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .box{
    width: 88%;
  }

  @media screen and (max-width: 600px) {
    .box{
      width: 84%;
    }
  }

  .faqTitle {
    height: 10%;
    width: 100%;

    .faqTitleImage {
      width: 62%;
      padding-top: 12%;
    }
    @media screen and (max-width: 600px) {

      .faqTitleImage {
        padding-top: 55px;
        width: 100%;
      }

    }
    @media screen and (max-width: 768px) {
      .faqTitleImage {
        padding-top: 55px;
        width: 95%;
      }
    }
    .headerBackground {
      background-color: transparent;
      padding-top: 220px;
      width: 100%;


      h1,h2 {
        margin:0;
      }
      .headerTitle {
        font-family: "CocogoosePro-Regular";
        font-size: 5rem;
        color:indigo;
        text-align: center;
        letter-spacing: 0.1cm;
        font-weight: normal;
  
      } 
    }

  }
  
  .question_container { 
    text-align: left; 
    padding-bottom: 330px;
    margin-top:15px;
    
    * {
      color: white;
    }

    .MuiExpansionPanel-rounded {
      background-color: rgba(152, 102, 27, 0.65);
      border-radius: 4px;
      margin-bottom: 4px;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .questionBox {
      font-family: "CocogoosePro-Regular";
      font-weight: normal;
      padding-top: 18px;
      padding-bottom: 18px;
      font-size: 24px;
      letter-spacing: .05cm;
    }
  
    .answerBox {
      font-family: "CocogoosePro-Regular";
      padding-bottom: 32px;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: .05cm;
    }

  } 

  .MuiExpansionPanel-root.Mui-expanded{
    margin: 10px!important;
  }

}