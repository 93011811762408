.castPage {
    width: 100%;
    min-height: 200vh;
    background-image: url("../../assets/compressedimages/CAST-BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .castTitle {
        width: 13%;
        padding-top: 12%;
    }

    @media screen and (max-width: 768px) {
        .castTitle {
            padding-top: 55px;
            width:20%;
        }
    }

    @media screen and (min-width: 5119px) {
        min-height: unset!important;
        height: 100vh;
        .castTitle {
            padding-top: 55px;
            width:10%;
        }
    }

    .castHeader {
        background-color: transparent;
        padding-top: 220px;
        width: 100%;

        h1 {
            margin:0;
        }
    }
    .cardsContainer {

        display:flex;
        align-items: center;
        justify-content: center;
        margin-top:20px;
        padding-bottom: 290px;
    }
    .cardContainer {

        margin: 10px 25px !important;
        max-width: 650px;
        background-color: rgba(152, 102, 27, 0.65);
        
    
        .imageContainer {
            width: 30%;
            max-width: 300px;
            padding-top: 8px;
            padding-bottom: 8px;
            
            .castImage {
                width: 100%; 
                height: 237px;   
            }
        }
    
        .textContainer {
            width: 65%;
            padding-left: 16px;
            text-align: left;
        
        p {
            margin:0;
        }
            .textName {
            font-family: "HelveticaExtraCompressed";
            font-size: 32px;
            color:white;
            padding-top:8px;
            letter-spacing: 3px;
            }

            .textRole {
            font-family: "HelveticaUltraCompressed";
            font-size: 28px;
            color: indigo;
            letter-spacing: 0.01em;
            }
            .textDescription {
            font-family: 'Avenir' sans-serif;
            color:white;
            font-size: 16px;
            padding-top:4px;
            }
            h2, h3 {
                margin: 0;
                padding-top:4px;
            }
            h3 {
                font-size: 1.5em;
                font-weight: 400;
                font-style: italic;
            }
    
            
        }
    
    }

    
}
@media only screen and (max-width: 600px) {
    .castPage {
        width: 100%;
        min-height: 200vh;
        background-image: url("../../assets/images/CASTCREATIVES-BG.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .castTitle {
            width: 30%;
            padding-top: 55px;
          }

        .cardContainer {
            max-width: 650px;
            background-color: rgba(152, 102, 27, 0.65);
            margin-left:25px !important; 
            align-items: flex-start;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-top: 4px;
            margin-bottom: 4px;
            margin-right: 25px!important;
        
            .imageContainer {
                width: 65%;
                max-width: 200px;
                padding-left: 8px;
                padding-top: 8px;
                padding-bottom: 8px;
                
                .castImage {
                    width: 100%; 
                    height: 237px;   
                }
            }
        
            .textContainer {
                width: 90%;
                padding-left: 16px;
                text-align: left;
            
            p {
                margin:0;
            }
                .textName {
                font-family: "HelveticaExtraCompressed";
                font-size: 32px;
                color:white;
                padding-top:8px;
                letter-spacing: 3px;
                }
    
                .textRole {
                font-family: "HelveticaUltraCompressed";
                font-size: 28px;
                color: indigo;
                }
                .textDescription {
                font-family: "Avenir";
                color:white;
                font-size: 20px;
                padding-top:4px;
                // letter-spacing: 0.05cm;
                }
    
            }
        
        }
}
}
