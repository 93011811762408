.faqModule {
    .cityDatePanelTicketButton {
        text-align: center;
        border: 2px solid white;
        border-radius: 4px;
        margin: 5px;
        color: #fff;
        transition: 0.8s;
        position: relative;
        background: rgba(106, 63, 135, 1);
        font-family: 'Anton', sans-serif;
        font-size: 24px;
        white-space: nowrap;
    }

    .cityDatePanelTicketButton:hover {
        color: white;
        background: rgb(213, 102, 28)!important;
        cursor: pointer;
    }

    .MuiButton-label:hover{
        color: white!important;
    }

    .ques_container {
        margin-left: 14%;
        margin-right: 14%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: rgba(152, 102, 27, 0.65);
        // max-height: 800px;
        color: #361e45;
        padding: 20px 10%;

        @media screen and (max-width: 940px) {
            margin-left: 5%;
            margin-right: 5%;
            padding: 20px 5%;

        }
    }

    .ques_container p {
        font-family: Avenir;
    }

    .heading {
        margin-top: 12%;
        margin-bottom: 25px;
        max-width: 40%;

        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
            margin-top: 55px;
            max-width: 80%;
        }

        @media screen and (max-width: 600px) {
            max-width: 85%!important;

            .second{
                width: 52%;
            }
        }
    }

    .heading.second {
        margin-top: 50px;
        width: 28%;

        @media screen and (max-width: 600px) {           
            width: 52%;           
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
            margin-top: 30px;
            width: 55%;
        }
    }

    .MuiTypography-root:not(:last-child) {
        margin-bottom: 10px;
    }
    .adjust {
        a {
            color: blue;
        }
    }
    @media screen and (max-width: 500px) {
        .seperator {
            display: none;
        }

        .adjust {
            display: block;
        }
    }

    .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 500px) {
            display: block;
        }
    }

    .desc {
        text-align: left;
        margin-right: 15px;

        p {
            margin: 0;
            margin-bottom: 5px;
        }

        @media screen and (max-width: 500px) {
            margin-right: 0;
        }
    }
    p, span{
        font-size: 20px;
    }
}