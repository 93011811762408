.SignUpModule {
    display: block;
    color: black;
    min-width: 100%;
    text-align: center;

    h2 {
        font-weight: bold;
        font-size: 300%;
        text-shadow: 2px 2px white;
    }
    
    .signupLink {
        text-decoration: none;

        * {
            font-family: 'Anton', sans-serif;
        }
    }

    .btn{
        border: 2px solid white;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 4px;
        margin: 10px;
        color: #fff;
        transition: 0.8s;
        position: relative;
        overflow: hidden;
        background: rgba(106, 63, 135, 1);
        width: 40%;
        max-width: 225px;
        text-transform: uppercase;
    }
    .btn:hover{
        color: white;
        background: rgb(213, 102, 28);
        cursor: pointer;
    }
    
    @media only screen and (max-width: 600px) {
        .btn {
            width: 40%;
            font-size: 20px;
            white-space: nowrap;
        }
    }

    @media only screen and (min-width: 600px) {
        margin-bottom: 20vh !important;
    }
}