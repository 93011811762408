.box {
  border: 2px solid white;
  padding: 15px 5%;
  border-radius: 4px;
  margin: 10px;
  color: #fff;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
  background: #6a3f87;
  font-family: 'Avenir', sans-serif !important;
  font-size: 27px;
  width: 70.5%;
  p {
    margin: 0;
  }
  a {
    color: #fff;
  }
}

.Tickets {
  text-align: center;
  background-image: url('../../assets/compressedimages/TICKETS-BG.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .footer{
    padding-top: 330px;
  }

  .VIPButtonDiv{
    padding-top: 135px;
    margin-right: 37px;
    justify-content: flex-end;

    .container .btn{
      width: 192px;
    }
  }

  .ticketTitle {
    padding-top: 0px;
    width: 48%;
  }

  .allTickets {
    .innerTicketList {
      padding-left: auto;
      padding-right: auto;
      //height: 1080px;
      width: 100%;
      overflow: auto;

      .dateTimeContainer {
        padding-top: 0px;

        .dateContainer {
          font-family: 'Avenir';
          font-size: 24px;
          padding-top: 12px;
          color: white;
          text-transform: uppercase;
          text-overflow: ellipsis;
          padding-left: 50px;
        }
      }

      .buyTicketButtonContainer {
        padding-top: 0px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
      }

      .cityContainer {
        color: white;
        letter-spacing: 0.01cm;
        text-transform: uppercase;
        font-family: 'Avenir'sans-serif;
        font-size: 24px;
        text-align: left;
        padding-top: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .locationContainer {
        color: rgba(54, 30, 69, 1);
        text-transform: uppercase;
        font-family: 'Avenir'sans-serif;
        font-size: 16px;
        text-align: left;
      }
    }
  }

  .ticketsHeader {
    background-color: transparent;
    width: 100%;
  }

  .ticketsTitle {
    padding-left: 24px;
    padding-right: 24px;
    text-shadow: 2px 2px black;
    font-weight: bold;
    color: white;
    text-align: center
  }

  .expansionPanelContainer {
    margin: 16px;
    background-color: rgba(152, 102, 27, 0.65)
  }

  .detailContainer {
    .showTimeTableContainer {
      padding-right: 20px;
      max-width: 75%;

      .dateIcon {
        font-size: 32px;
        color: black;
      }

      .dateIconText {
        font-size: 18px;
        margin: 0;
        color: black;
        font-family: 'Avenir'sans-serif;
      }

      .timeIcon {
        font-size: 32px;
        color: black;
      }

      .timeIconText {
        font-size: 18px;
        margin: 0;
        color: black;
        font-family: 'Avenir'sans-serif;
      }
    }

    .ticketTextContainer {
      padding-left: 20px;
    }

    .contactInfoContainer {
      padding-left: 40px;
    }
  }

  .cityDatePanelTicketButton {
    text-align: center;
    border: 2px solid white;
    border-radius: 4px;
    margin: 5px;
    color: #fff;
    transition: 0.8s;
    position: relative;
    background: rgba(106, 63, 135, 1);
    font-family: 'Anton', sans-serif;
    font-size: 24px;
  }

  .cityDatePanelTicketButton:hover {
    color: white;
    background-color: rgb(213, 102, 28)!important;
    cursor: pointer;
  }

  .MuiButton-label:hover{
    color: white!important;
  }
  .heading-text {
    padding-bottom: 25px;
    padding-left: 30px;
    font-size: 25px;
    text-align: center;
    font-family: 'CocogoosePro-Regular', sans-serif !important;
    color: indigo !important;
  }

  .linkStyle {
    color: blue;
    transition: 0.3s all;
    margin-left: 5px;

    &:hover {
      transition: 0.3s all;
    }
  }
}

@media only screen and (max-width: 960px) {
  .Tickets {
    text-align: center;
    background-image: url('../../assets/compressedimages/TICKETS-BG.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    .ticketTitle {
      padding-top: 55px;
      padding-bottom: 20px;
      width: 75%;
    }

    .allTickets {
      .innerTicketList {
        padding-left: auto;
        padding-right: auto;
        width: 100%;
        //height: 100%;

        .dateTimeContainer {
          padding-top: 12px;

          .dateContainer {
            font-family: 'Avenir'sans-serif;
            font-size: 22px;
            padding-top: 0px;
            color: white;
            text-transform: uppercase;
            text-overflow: ellipsis;
          }
        }

        .buyTicketButtonContainer {
          padding-top: 0px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;    
        }

        .cityContainer {
          color: white;
          letter-spacing: 0.01cm;
          text-transform: uppercase;
          font-family: 'Avenir'sans-serif;
          font-size: 24px;
          text-align: left;
          padding-top: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          flex-grow: 1;
        }

        .locationContainer {
          color: rgba(54, 30, 69, 1);
          text-transform: uppercase;
          font-family: 'Avenir'sans-serif;
          font-size: 16px;
          text-align: left;
        }
      }
    }

    .ticketsHeader {
      background-color: transparent;
      // height: 100px;
      width: 100%;
    }

    .ticketsTitle {
      padding-left: 24px;
      padding-right: 24px;
      text-shadow: 2px 2px black;
      font-weight: bold;
      color: white;
      text-align: center
    }

    .expansionPanelContainer {
      margin: 16px;
      background-color: rgba(152, 102, 27, 0.65)
    }

    .detailContainer {
      .showTimeTableContainer {
        padding-right: 20px;
        max-width: 100%;

        .dateIcon {
          font-size: 32px;
          color: black;
        }

        .dateIconText {
          font-size: 18px;
          margin: 0;
          color: black;
          font-family: 'Avenir'sans-serif;
        }

        .timeIcon {
          font-size: 32px;
          color: black;
        }

        .timeIconText {
          font-size: 18px;
          margin: 0;
          color: black;
          font-family: 'Avenir'sans-serif;
        }
      }
    }

    .cityDatePanelTicketButton {
      text-align: center;
      border: 2px solid white;
      border-radius: 4px;
      margin: 0px;
      color: #fff;
      transition: 0.8s;
      position: relative;
      background: rgba(106, 63, 135, 1);
      font-family: 'Anton', sans-serif;
      font-size: 24px;
    }

    .cityDatePanelTicketButton:hover {
      color: white;
      background: rgb(213, 102, 28);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Tickets {
    .ticketTitle {
      padding-top: 55px;
      padding-bottom: 0px;
      width: 75%;
    }
  }
}


@media only screen and (max-width: 812px) {
  .Tickets{
  
    .VIPButtonDiv{
        display: none;
    }
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .Tickets .VIPButtonDiv .container .btn {
    width: 157px;
    font-size: 32px;
  }

  .Tickets .buyTicketButtonContainer .MuiButton-root .MuiButton-label{
    // font-size: unset;
    white-space: nowrap;
  } 
}