.videoPlayer {
    height: 96vh;
    max-height: 900px
}

@media only screen and (max-width: 600px) {
    .videoPlayer {
        height: 280px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 800px) {
    .videoPlayer {
        height: 400px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
    .videoPlayer {
        height: 500px;
    }
}