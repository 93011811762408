.touPage {
  background-image: url('../../assets/compressedimages/Gallery_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;

  .headerImg{
    width: 30%;
  }
  .touText {
      padding-top: 200px;
      padding-bottom: 150px;
      .touTitle {
          font-family: "HelveticaUltraCompressed";
      }
      .touParagraph {
          padding-left: 100px;
          padding-right: 100px;
          padding-bottom: 30px;
          text-align: left;

          p a{
              color: #6a3f87;
          }
      }
  }

  .footer{
      padding-top: 175px!important;
  }
}

@media only screen and (max-width: 812px) {
    .touPage {
        .touText {
            padding-top: 55px;
            padding-bottom: 50px;
        }
      }
      .headerImg{
          width: 60%!important;
      }
}
@media only screen and (max-width: 600px) {
  .touPage {
    .touParagraph {
        padding-left: 20px!important;
        padding-right: 20px!important;
        text-align: left!important;
    } 
  }
}