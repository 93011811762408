@media only screen and (min-width: 400px) {
    .btn{
        font-size: 40px;
    }
}
@media only screen and (max-width: 400px) {
    .btn{
        font-size: 17px;
    }
}
@media only screen and (max-width: 320px) {
    .btn{
        font-size: 12px;
        left: 12px;
    }
}

@media only screen and (max-width: 600px) {
    .bottom{
        .btn{
            font-size: 20px!important;
            width: 40%!important;            
        }

       
    }    
}

@media only screen and (min-width: 600px) {
    .bottom{
        .btn{
            font-size: 35px!important;
            width: 40%!important;    
            max-width: 240px!important;        
        }

        .SignUpModule .btn{
            font-size: 35px!important;
            width: 40%!important; 
            max-width: 240px!important;     
        }
    }    
}

.container{
    text-align: center;
    .btn{
        border: 2px solid white;
        padding: 5px 5px;
        border-radius: 4px;
        margin: 10px;
        color: #fff;
        transition: 0.8s;
        position: relative;
        overflow: hidden;
        background: rgba(106, 63, 135, 1);
        font-family: 'Anton', sans-serif;
        text-transform: uppercase;
        text-transform: uppercase;
    }
    .btn:hover{
        color: white;
        background: rgb(213, 102, 28);
        cursor: pointer;
    }
}