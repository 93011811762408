.MuiListItem-root{
    color: white!important;
    height: 40px!important;
    width: 125px!important;
    font-size: 0.75em!important;
}

.MuiListItem-root:hover{
    background-color: #d5661c!important; 
}

.MuiButton-label{
    font-family: 'CocogoosePro-Regular'!important;  
}

.MuiButton-label:hover{
    color: #d5661c!important;   
}

.MuiButton-root
{
    font-size: 1em!important;
}

.MuiMenuItem-root{
    min-height: 0!important;
}

.MuiButton-text{
    padding: 0 12px!important;
}

.MuiButton-root:hover{
    background-color: unset!important;
}

.MuiList-root .MuiButtonBase-root{
    border-bottom: 1px solid white!important;
}

.MuiList-root .MuiButtonBase-root:last-child{
    border-bottom: unset!important;
}

@media only screen and (max-width:600px) {

    .MuiListItem-root{        
        width: unset!important;
    }

}

@media only screen and (min-width: 601px) and (max-width: 1023px) and (orientation: landscape){
    
    .MuiListItem-root{        
        width: unset!important;
    }

}