body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/AvenirLTStd-Black.woff") format("woff");
}

@font-face {
  font-family: "CoCogoose";
  src: url("../../core/assets/fonts/CocogoosePro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaBold";
  src: url("../../core/assets/fonts/Helvetica-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaExtraCompressed";
  src: url("../../core/assets/fonts/Helvetica-ExtraCompressed.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLTStdBlkCn";
  src: url("../../core/assets/fonts/HelveticaNeueLTStd-BlkCn.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaUltraCompressed";
  src: url("../../core/assets/fonts/HelveticaUltraCompressed.ttf") format("truetype");
}

@font-face {
  font-family: "NeulandInline";
  src: url("../../core/assets/fonts/Neuland-Inline.ttf") format("truetype");
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: 1s fadeIn;
  font-family: Avenir;

  .mobilePageContainer {}

  .desktopPageContainer {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  h1 {
    font-size: 3.5em;
  }
}

.loadingContainer {
  height: 100vh;
  width: 100vw;
  opacity: 1;
  position: fixed;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fadeIn {
  animation: 1s fadeIn;
  opacity: 0;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  height: 40px;
  display: flex;
  align-items: center;
}

.loadingImage {
  width: 50%;
  max-width: 400px;
}

.obj {
  width: 6px;
  height: 40px;
  background: purple;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}

.obj:nth-child(2) {
  animation-delay: 0.1s;
}

.obj:nth-child(3) {
  animation-delay: 0.2s;
}

.obj:nth-child(4) {
  animation-delay: 0.3s;
}

.obj:nth-child(5) {
  animation-delay: 0.4s;
}

.obj:nth-child(6) {
  animation-delay: 0.5s;
}

.obj:nth-child(7) {
  animation-delay: 0.6s;
}

.obj:nth-child(8) {
  animation-delay: 0.7s;
}

@keyframes loading {
  0% {
    height: 0;
  }

  50% {
    height: 40px;
  }

  100% {
    height: 0;
  }
}

.hidden {
  display: none !important;
}

.bg-theme {
  .MuiPaper-root {
    // background-color: #FAEEA4;
    // color: #361e45;
    border: 2px solid white;
    padding: 5px 5px;
    border-radius: 4px;
    margin: 10px;
    color: #fff;
    transition: 0.8s;
    position: relative;
    overflow: hidden;
    background: #6a3f87;
    font-family: 'Anton', sans-serif;
  }

  .MuiSvgIcon-root {
    // fill: #361e45;
    fill: white;
  }
}

.bg-theme *  {
  font-family: Avenir !important;
}