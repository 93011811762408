@import url('https://fonts.googleapis.com/css?family=Anton&display=swap');

//desktop
@media only screen and (min-width: 766px) {
    .contactFormContainer{
        text-decoration-color:  rgba(128,0,128, .22);
        text-align: center;
        justify-self: center;
        padding-right: 40px;
        padding-bottom: 315px;
        width: 100%;
        font-family: 'HelveticaUltraCompressed', sans-serif;
        color: indigo;
        input{
            font-family: 'HelveticaUltraCompressed', sans-serif;
            font-size: 25px;
            letter-spacing: .1em;
            padding-left: 20px;
            border-radius: 5px;
            height: 50px;
            width: 500px;
        }
        select{
            font-family: 'HelveticaUltraCompressed', sans-serif;
            font-size: 25px;
            letter-spacing: .1em;
            padding-left: 20px;
            border-radius: 5px;
            height: 40px;
            width: 500px;
        }
        .MuiGrid-container{
            padding-top: 40px;
            padding-bottom:40px;
            background-color: rgba(152, 102, 27, 0.65);
            width: 650px;
            align-self: center;
            margin-left: 65px;
        }
        p{
            font-size: 25px;
            text-align: center;
        }
        h3{
            color: indigo;
            font-weight: 500;
            font-size: 25px;
            text-shadow: .5px .5px rgb(49, 49, 49);
            text-align: left;
        }
        .topText{
            padding-bottom: 30px;
            padding-left: 30px;
        }
        .friendsText{
            float: left;
        }
        .signUpBtnCF {
            background-color: rgba(106, 63, 135, 1);
            color: white;
            border-radius: 4px;
            font-size: 25px;
            font-family: 'Anton', sans-serif;
            border: 2px solid white;
            margin-top: 20px;
            padding: 10px 10px;
        
            * {
                color: white;
            }
        }
        .signUpBtnCF:hover {
            cursor: pointer;
        }
    }
}
//mobile
  @media only screen and (max-width: 500px) {
    .contactFormContainer{
        text-align: center;
        padding-bottom: 100px;
        width:100%;
        box-sizing: content-box;
        padding-left: 20px;
        padding-right: 20px;
        color: indigo;
        font-family: 'HelveticaUltraCompressed', sans-serif;

        input{
            font-family: 'HelveticaUltraCompressed', sans-serif;
            font-size: 25px;
            letter-spacing: .1em;
            padding-left: 5px;
            border-radius: 5px;
            height: 35px;
            width: 280px;
        }
        select{
            font-family: 'HelveticaUltraCompressed', sans-serif;
            font-size: 25px;
            letter-spacing: .1em;
            padding-left: 20px;
            border-radius: 5px;
            height: 35px;
            width: 250px;
        }
        form{
            height: 100%;
            width: 100%;
            .MuiGrid-container{
                padding-top: 15px;
                padding-bottom: 20px;
                margin-left: 0px;
                // width: 350px;
                width: 100%;
                background-color: rgba(152, 102, 27, 0.65);
            }
        }
        .topText{
            padding-bottom: 10px;
        }
        h3{
            color: indigo;
            font-weight: 500;
            text-shadow: .5px .5px rgb(49, 49, 49);
            text-align: left;
        }
        .signUpBtnCF {
            background-color: rgba(106, 63, 135, 1);
            color: white;
            border-radius: 4px;
            font-size: 25px;
            font-family: 'Anton', sans-serif;
            border: 2px solid white;
            margin-top: 20px;
            padding: 10px 10px;
        
            * {
                color: white;
            }
        }
        .signUpBtnCF:hover {
            cursor: pointer;
        }
    }
}
//tablet
@media only screen and (min-width: 500px) and (max-width: 766px) {
    .contactFormContainer{
        text-align: center;
        padding-bottom: 100px;
        width:100%;
        box-sizing: content-box;
        padding-right: 20px;
        color: indigo;
        font-family: 'HelveticaUltraCompressed', sans-serif;
        input{
            font-family: 'HelveticaUltraCompressed', sans-serif;
            font-size: 25px;
            letter-spacing: .1em;
            padding-left: 50px;
            border-radius: 5px;
            height: 40px;
            width: 400px;
        }
        select{
            font-family: 'HelveticaUltraCompressed', sans-serif;
            font-size: 25px;
            letter-spacing: .1em;
            padding-left: 20px;
            border-radius: 5px;
            height: 40px;
            width: 400px;
        }
        .MuiGrid-container{
            padding-top: 40px;
            padding-bottom:40px;
            background-color: rgba(152, 102, 27, 0.65);
            width: 500px;
            align-self: center;
            margin-left: 35px;
        }
        .topText{
            padding-bottom: 10px;
        }
        p{
            font-size: 18px;
            padding-left: 20px;
        }
        h3{
            color: indigo;
            font-weight: 500;
            text-shadow: .5px .5px rgb(49, 49, 49);
            text-align: left;
        }
        .signUpBtnCF {
            background-color: rgba(106, 63, 135, 1);
            color: white;
            border-radius: 4px;
            font-size: 25px;
            font-family: 'Anton', sans-serif;
            border: 2px solid white;
            margin-top: 20px;
            padding: 10px 10px;
        
            * {
                color: white;
            }
        }
        .signUpBtnCF:hover {
            cursor: pointer;
        }
    }
}