//tablet
@media only screen and (min-width: 600px) and (max-width: 800px){
  .vipText{
    padding-top: 55px;
    .headerImg{
      width: 60%;
      margin: 20px;
    }
    .vipContainer {
      padding-left: 50px;
      padding-right: 50px;
      .vipPreParty{
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
      }
      .vipPackage{
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
      }
    }
  }
}
//phone
@media only screen and (max-width: 600px) {
  .vipText{
    padding-top: 5%;
    .headerImg{
      width: 70%;
      padding-bottom: 5%;
      padding-top: 55px;
    }
    .vipContainer {
      padding-left: 50px;
      padding-right: 50px;
      .vipPreParty{
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
      }
      .vipPackage{
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
      }
    }
  }
}
//desktop
@media only screen and (min-width: 800px) {
  .vipText{
    padding-top:12%;
    .headerImg{
      width: 30%;
      margin: 10px;
      margin-top: 0!important;
    }
    .vipContainer {
      padding-left: 15%;
      padding-right: 15%;
      .vipPreParty{
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
      }
      .vipPackage{
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
      }
    }
  }
}

.vipPage {
background-image: url('../../assets/compressedimages/Gallery_bg.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
width: 100%;
.vipText {
  .vipContainer {
    text-decoration: none;
    font-family: 'CocogoosePro-Regular' sans-serif;
    font-size: 15px;
    color: #361e45;
    padding-bottom: 310px;
    li{
      text-align: left;
    }
    .vipPreParty{
      background-color: rgba(152, 102, 27, 0.65);
      margin-bottom: 20px;
    }
    .vipPackage{
      background-color: rgba(152, 102, 27, 0.65);
    }
  }
}
}