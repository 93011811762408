.footer{
  background-color: transparent;
  min-height: 250;
  max-width: 100%;
  padding-top: 18px;


  .links {
    a {
      text-decoration: none;
      color: rgba(54, 30, 69, 1);
      font-family: "CocogoosePro-Regular", sans-serif;
      letter-spacing: 1px;
      font-size: 26px;
      font-weight: 500px;
    }
    a:hover {
      color: rgb(213, 102, 28);
    }

  }
  .footer-container {
    min-height: 165px;
    margin-top: 5px;
    max-width: 100%;
    padding-right: 10px;

    .footer-container-tickets{
      max-width: 100%;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
  .top-footer {
    max-width: 100%;
    font-family: "CocogoosePro-Regular";

    .footer-link {
      display: none;
      margin-top: 6px;
      margin-bottom: 6px;
      margin-left: 12px;
      margin-right: 12px;
      text-align: center;
    }

  }

  .mainfooter-container-image {
    padding-top: 12px;
    padding-left: 20px;

    .footer-logo {
      height: 116px;
      width: 280px;
    }
  }
  .footer-container-social-container {
    padding-left: 18px;
    p {
      margin:0;
      text-align: center;
      padding-left: 16px;
      font-family: "CocogoosePro-Regular";
      font-size: 10px;
    }
  }
  .footer-container-social-container-mobile {
    p {
      margin:0;
      font-family: "CocogoosePro-Regular";
      font-size: 10px;
    }
  }
  .icon-style {
    text-align:center;
    padding-top: 12px;

      .social-style {

        img {
          height: 41px;
          width: 41px;
          padding-left: 4px;
          padding-right: 4px;
        }

      }
  }
  .mainbottom-footer{
    max-width: 100%;
    font-family: "CocogoosePro-Regular";
    text-transform: uppercase;
    padding-left: 20px;

    .footer-info {
      a {
        font-size: 14px;
      }

    }
    .footer-contactus {
      padding-left: 6px;
      padding-right: 6px;
    }
    .footer-Privacy {
      padding-left: 6px;
      padding-right: 6px;
    }
    .footer-terms {
      padding-left: 6px;
      padding-right: 6px;
    }

    .footer-verticalpipe {
      color: orangered;
      padding-left: 6px;
      padding-right: 6px;
      font-weight: 900;
    }

    .bottom-footer-additional-text {
      font-size: 10px;
    }
  }
  .bottom-footer-legal-text{
    font-size: 10px;
    margin-top:12px;
    color: white;
    margin-bottom: 12px;
  }

  .footer-container-copyright-container {
      padding-right: 12px;
      width: 100%;
      margin-bottom: 12px;
      text-align: right;
      p {
        margin:0;
        text-align: right;
      }
      @media screen and (max-width: 950px) {
      p {
        text-align: center;
      }
      }

    .monloveLogo {
      padding-right: 8px;
      width: 24%;
      min-width: 48px;
      max-width: 48px;
    }
    .monlove {
      // width: 24%;
      max-height: 168px;
      max-width: 178px;
    }
    .footer-legal-notes{
      font-family: "Avenir";
      font-size: 8px;
      // height: 25px;
    }
  }
  @media screen and (max-width: 600px) {
    // .footer-container-image {
    //   margin-top: 12px;

    //   .footer-logo {
    //     height: 152px;
    //     width: 304px;
    //   }

    // }
    
      .footer-container{
          padding: 2px!important;
       }

      .copyright-container{
        padding-right: 0!important;
      }

      .mainbottom-footer{
        padding-left: 0!important;
      }

      .mainfooter-container-image {
        padding-left: 0!important;
      }
    
  }

  @media screen and (max-width: 950px) {
  .footer-container-copyright-container {
    text-align: center;
  }
}

  @media only screen and (min-width: 1023px) and (max-width: 1024px){

    .mainfooter-container-image{
      padding-left: 58px;
    }

    .mainbottom-footer{
      padding-left: 125px;
    }

    .mainbottom-footer .footer-info a {
      font-size: 12.5px;
  }

  }

  @media only screen and (min-width: 601px) and (max-width: 1023px){

  .mainfooter-container-image{
    padding-left: 0;
  }

  .mainbottom-footer{
    padding-left: 0;
  }
}

}

.LogoFooter{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33.3%;
  position: relative;
  height: 100%;

  .scoobyFooter{
    width: auto;      
    // padding-right: 17%;
    padding-top: 5%;
    position: absolute;
    bottom: 0;
    height: 500px;

  }
}
