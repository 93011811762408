@import url(https://fonts.googleapis.com/css?family=Anton&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'CocogoosePro-Regular';
  src: local('CocogoosePro-Regular'), url(/static/media/CocogoosePro-Regular.03085fa6.ttf) format('truetype');
}
@media only screen and (min-width: 680px) {
  .headerLinks {
    position: absolute;
    float: right;
    top: 45px;
    right: 24px; } }

@media only screen and (max-width: 1130px) {
  .desktopHeader .logo {
    max-width: 240px !important; } }

.desktopHeader {
  position: absolute;
  z-index: 1;
  min-width: 100%;
  height: 170px; }
  .desktopHeader .logo {
    max-width: 260px; }
    .desktopHeader .logo a {
      position: block;
      width: 100%;
      height: 100%; }
    .desktopHeader .logo img {
      padding-top: 12px;
      padding-left: 12px;
      width: 100%; }
  @media only screen and (min-width: 938px) and (max-width: 1040px) {
    .desktopHeader .btn {
      font-size: 32px !important; }
    .desktopHeader .logo {
      max-width: 200px !important; } }
  @media only screen and (min-width: 680px) and (max-width: 938px) {
    .desktopHeader .logo {
      padding-top: 12px;
      padding-left: 12px;
      max-width: 160px !important; } }
  .desktopHeader .headerLinks a {
    padding-right: 12px;
    padding-left: 12px;
    color: #361e45;
    text-decoration: none;
    font-weight: 500;
    font-family: "CocogoosePro-Regular";
    font-size: 1em;
    text-transform: uppercase; }
  .desktopHeader .headerLinks a:hover {
    color: #d5661c; }

.mobileHeader {
  min-width: 100%;
  background-color: #6a3f87;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px; }
  .mobileHeader a {
    text-decoration: none;
    font-family: "CocogoosePro-Regular"; }
  .mobileHeader .container {
    text-align: center; }
    .mobileHeader .container .btn {
      font-size: 1em !important; }

.burgerMenu .menuButton {
  color: white;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  position: fixed;
  right: 4px;
  top: 4px;
  z-index: 9999999; }

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
  width: 40%;
  background-color: #6a3f87;
  top: 53px !important;
  left: unset !important;
  right: 10px; }

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  justify-content: center;
  background-color: #6a3f87;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button a:hover {
    color: black; }

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button :hover {
  text-align: center;
  height: 100%;
  background-color: #572b75;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center; }

.MuiMenu-paper {
  width: 100%;
  text-align: center; }
  .MuiMenu-paper a {
    font-weight: 500;
    font-family: "CocogoosePro-Regular";
    font-size: 1em;
    text-decoration: none;
    color: white; }

.MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

@media only screen and (max-width: 812px) {
  .MuiMenu-paper a {
    width: 100%;
    text-align: center; }
  .MuiList-root .MuiListItem-root {
    width: unset !important; } }

@media only screen and (min-width: 400px) {
  .btn {
    font-size: 40px; } }

@media only screen and (max-width: 400px) {
  .btn {
    font-size: 17px; } }

@media only screen and (max-width: 320px) {
  .btn {
    font-size: 12px;
    left: 12px; } }

@media only screen and (max-width: 600px) {
  .bottom .btn {
    font-size: 20px !important;
    width: 40% !important; } }

@media only screen and (min-width: 600px) {
  .bottom .btn {
    font-size: 35px !important;
    width: 40% !important;
    max-width: 240px !important; }
  .bottom .SignUpModule .btn {
    font-size: 35px !important;
    width: 40% !important;
    max-width: 240px !important; } }

.container {
  text-align: center; }
  .container .btn {
    border: 2px solid white;
    padding: 5px 5px;
    border-radius: 4px;
    margin: 10px;
    color: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    position: relative;
    overflow: hidden;
    background: #6a3f87;
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;
    text-transform: uppercase; }
  .container .btn:hover {
    color: white;
    background: #d5661c;
    cursor: pointer; }

.MuiListItem-root {
  color: white !important;
  height: 40px !important;
  width: 125px !important;
  font-size: 0.75em !important; }

.MuiListItem-root:hover {
  background-color: #d5661c !important; }

.MuiButton-label {
  font-family: "CocogoosePro-Regular" !important; }

.MuiButton-label:hover {
  color: #d5661c !important; }

.MuiButton-root {
  font-size: 1em !important; }

.MuiMenuItem-root {
  min-height: 0 !important; }

.MuiButton-text {
  padding: 0 12px !important; }

.MuiButton-root:hover {
  background-color: unset !important; }

.MuiList-root .MuiButtonBase-root {
  border-bottom: 1px solid white !important; }

.MuiList-root .MuiButtonBase-root:last-child {
  border-bottom: unset !important; }

@media only screen and (max-width: 600px) {
  .MuiListItem-root {
    width: unset !important; } }

@media only screen and (min-width: 601px) and (max-width: 1023px) and (orientation: landscape) {
  .MuiListItem-root {
    width: unset !important; } }

.box {
  border: 2px solid white;
  padding: 15px 5%;
  border-radius: 4px;
  margin: 10px;
  color: #fff;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
  background: #6a3f87;
  font-family: 'Avenir', sans-serif !important;
  font-size: 27px;
  width: 70.5%; }
  .box p {
    margin: 0; }
  .box a {
    color: #fff; }

.Tickets {
  text-align: center;
  background-image: url(/static/media/TICKETS-BG.17474b6a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh; }
  .Tickets .footer {
    padding-top: 330px; }
  .Tickets .VIPButtonDiv {
    padding-top: 135px;
    margin-right: 37px;
    justify-content: flex-end; }
    .Tickets .VIPButtonDiv .container .btn {
      width: 192px; }
  .Tickets .ticketTitle {
    padding-top: 0px;
    width: 48%; }
  .Tickets .allTickets .innerTicketList {
    padding-left: auto;
    padding-right: auto;
    width: 100%;
    overflow: auto; }
    .Tickets .allTickets .innerTicketList .dateTimeContainer {
      padding-top: 0px; }
      .Tickets .allTickets .innerTicketList .dateTimeContainer .dateContainer {
        font-family: 'Avenir';
        font-size: 24px;
        padding-top: 12px;
        color: white;
        text-transform: uppercase;
        text-overflow: ellipsis;
        padding-left: 50px; }
    .Tickets .allTickets .innerTicketList .buyTicketButtonContainer {
      padding-top: 0px;
      display: flex;
      align-items: flex-end;
      flex-direction: column; }
    .Tickets .allTickets .innerTicketList .cityContainer {
      color: white;
      letter-spacing: 0.01cm;
      text-transform: uppercase;
      font-family: 'Avenir'sans-serif;
      font-size: 24px;
      text-align: left;
      padding-top: 12px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .Tickets .allTickets .innerTicketList .locationContainer {
      color: #361e45;
      text-transform: uppercase;
      font-family: 'Avenir'sans-serif;
      font-size: 16px;
      text-align: left; }
  .Tickets .ticketsHeader {
    background-color: transparent;
    width: 100%; }
  .Tickets .ticketsTitle {
    padding-left: 24px;
    padding-right: 24px;
    text-shadow: 2px 2px black;
    font-weight: bold;
    color: white;
    text-align: center; }
  .Tickets .expansionPanelContainer {
    margin: 16px;
    background-color: rgba(152, 102, 27, 0.65); }
  .Tickets .detailContainer .showTimeTableContainer {
    padding-right: 20px;
    max-width: 75%; }
    .Tickets .detailContainer .showTimeTableContainer .dateIcon {
      font-size: 32px;
      color: black; }
    .Tickets .detailContainer .showTimeTableContainer .dateIconText {
      font-size: 18px;
      margin: 0;
      color: black;
      font-family: 'Avenir'sans-serif; }
    .Tickets .detailContainer .showTimeTableContainer .timeIcon {
      font-size: 32px;
      color: black; }
    .Tickets .detailContainer .showTimeTableContainer .timeIconText {
      font-size: 18px;
      margin: 0;
      color: black;
      font-family: 'Avenir'sans-serif; }
  .Tickets .detailContainer .ticketTextContainer {
    padding-left: 20px; }
  .Tickets .detailContainer .contactInfoContainer {
    padding-left: 40px; }
  .Tickets .cityDatePanelTicketButton {
    text-align: center;
    border: 2px solid white;
    border-radius: 4px;
    margin: 5px;
    color: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    position: relative;
    background: #6a3f87;
    font-family: 'Anton', sans-serif;
    font-size: 24px; }
  .Tickets .cityDatePanelTicketButton:hover {
    color: white;
    background-color: #d5661c !important;
    cursor: pointer; }
  .Tickets .MuiButton-label:hover {
    color: white !important; }
  .Tickets .heading-text {
    padding-bottom: 25px;
    padding-left: 30px;
    font-size: 25px;
    text-align: center;
    font-family: 'CocogoosePro-Regular', sans-serif !important;
    color: indigo !important; }
  .Tickets .linkStyle {
    color: blue;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    margin-left: 5px; }
    .Tickets .linkStyle:hover {
      -webkit-transition: 0.3s all;
      transition: 0.3s all; }

@media only screen and (max-width: 960px) {
  .Tickets {
    text-align: center;
    background-image: url(/static/media/TICKETS-BG.17474b6a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; }
    .Tickets .ticketTitle {
      padding-top: 55px;
      padding-bottom: 20px;
      width: 75%; }
    .Tickets .allTickets .innerTicketList {
      padding-left: auto;
      padding-right: auto;
      width: 100%; }
      .Tickets .allTickets .innerTicketList .dateTimeContainer {
        padding-top: 12px; }
        .Tickets .allTickets .innerTicketList .dateTimeContainer .dateContainer {
          font-family: 'Avenir'sans-serif;
          font-size: 22px;
          padding-top: 0px;
          color: white;
          text-transform: uppercase;
          text-overflow: ellipsis; }
      .Tickets .allTickets .innerTicketList .buyTicketButtonContainer {
        padding-top: 0px;
        display: flex;
        align-items: flex-start;
        flex-direction: column; }
      .Tickets .allTickets .innerTicketList .cityContainer {
        color: white;
        letter-spacing: 0.01cm;
        text-transform: uppercase;
        font-family: 'Avenir'sans-serif;
        font-size: 24px;
        text-align: left;
        padding-top: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1; }
      .Tickets .allTickets .innerTicketList .locationContainer {
        color: #361e45;
        text-transform: uppercase;
        font-family: 'Avenir'sans-serif;
        font-size: 16px;
        text-align: left; }
    .Tickets .ticketsHeader {
      background-color: transparent;
      width: 100%; }
    .Tickets .ticketsTitle {
      padding-left: 24px;
      padding-right: 24px;
      text-shadow: 2px 2px black;
      font-weight: bold;
      color: white;
      text-align: center; }
    .Tickets .expansionPanelContainer {
      margin: 16px;
      background-color: rgba(152, 102, 27, 0.65); }
    .Tickets .detailContainer .showTimeTableContainer {
      padding-right: 20px;
      max-width: 100%; }
      .Tickets .detailContainer .showTimeTableContainer .dateIcon {
        font-size: 32px;
        color: black; }
      .Tickets .detailContainer .showTimeTableContainer .dateIconText {
        font-size: 18px;
        margin: 0;
        color: black;
        font-family: 'Avenir'sans-serif; }
      .Tickets .detailContainer .showTimeTableContainer .timeIcon {
        font-size: 32px;
        color: black; }
      .Tickets .detailContainer .showTimeTableContainer .timeIconText {
        font-size: 18px;
        margin: 0;
        color: black;
        font-family: 'Avenir'sans-serif; }
    .Tickets .cityDatePanelTicketButton {
      text-align: center;
      border: 2px solid white;
      border-radius: 4px;
      margin: 0px;
      color: #fff;
      -webkit-transition: 0.8s;
      transition: 0.8s;
      position: relative;
      background: #6a3f87;
      font-family: 'Anton', sans-serif;
      font-size: 24px; }
    .Tickets .cityDatePanelTicketButton:hover {
      color: white;
      background: #d5661c;
      cursor: pointer; } }

@media only screen and (max-width: 600px) {
  .Tickets .ticketTitle {
    padding-top: 55px;
    padding-bottom: 0px;
    width: 75%; } }

@media only screen and (max-width: 812px) {
  .Tickets .VIPButtonDiv {
    display: none; } }

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .Tickets .VIPButtonDiv .container .btn {
    width: 157px;
    font-size: 32px; }
  .Tickets .buyTicketButtonContainer .MuiButton-root .MuiButton-label {
    white-space: nowrap; } }

.footer {
  background-color: transparent;
  min-height: 250;
  max-width: 100%;
  padding-top: 18px; }
  .footer .links a {
    text-decoration: none;
    color: #361e45;
    font-family: "CocogoosePro-Regular", sans-serif;
    letter-spacing: 1px;
    font-size: 26px;
    font-weight: 500px; }
  .footer .links a:hover {
    color: #d5661c; }
  .footer .footer-container {
    min-height: 165px;
    margin-top: 5px;
    max-width: 100%;
    padding-right: 10px; }
    .footer .footer-container .footer-container-tickets {
      max-width: 100%;
      margin-top: 6px;
      margin-bottom: 6px; }
  .footer .top-footer {
    max-width: 100%;
    font-family: "CocogoosePro-Regular"; }
    .footer .top-footer .footer-link {
      display: none;
      margin-top: 6px;
      margin-bottom: 6px;
      margin-left: 12px;
      margin-right: 12px;
      text-align: center; }
  .footer .mainfooter-container-image {
    padding-top: 12px;
    padding-left: 20px; }
    .footer .mainfooter-container-image .footer-logo {
      height: 116px;
      width: 280px; }
  .footer .footer-container-social-container {
    padding-left: 18px; }
    .footer .footer-container-social-container p {
      margin: 0;
      text-align: center;
      padding-left: 16px;
      font-family: "CocogoosePro-Regular";
      font-size: 10px; }
  .footer .footer-container-social-container-mobile p {
    margin: 0;
    font-family: "CocogoosePro-Regular";
    font-size: 10px; }
  .footer .icon-style {
    text-align: center;
    padding-top: 12px; }
    .footer .icon-style .social-style img {
      height: 41px;
      width: 41px;
      padding-left: 4px;
      padding-right: 4px; }
  .footer .mainbottom-footer {
    max-width: 100%;
    font-family: "CocogoosePro-Regular";
    text-transform: uppercase;
    padding-left: 20px; }
    .footer .mainbottom-footer .footer-info a {
      font-size: 14px; }
    .footer .mainbottom-footer .footer-contactus {
      padding-left: 6px;
      padding-right: 6px; }
    .footer .mainbottom-footer .footer-Privacy {
      padding-left: 6px;
      padding-right: 6px; }
    .footer .mainbottom-footer .footer-terms {
      padding-left: 6px;
      padding-right: 6px; }
    .footer .mainbottom-footer .footer-verticalpipe {
      color: orangered;
      padding-left: 6px;
      padding-right: 6px;
      font-weight: 900; }
    .footer .mainbottom-footer .bottom-footer-additional-text {
      font-size: 10px; }
  .footer .bottom-footer-legal-text {
    font-size: 10px;
    margin-top: 12px;
    color: white;
    margin-bottom: 12px; }
  .footer .footer-container-copyright-container {
    padding-right: 12px;
    width: 100%;
    margin-bottom: 12px;
    text-align: right; }
    .footer .footer-container-copyright-container p {
      margin: 0;
      text-align: right; }
    @media screen and (max-width: 950px) {
      .footer .footer-container-copyright-container p {
        text-align: center; } }
    .footer .footer-container-copyright-container .monloveLogo {
      padding-right: 8px;
      width: 24%;
      min-width: 48px;
      max-width: 48px; }
    .footer .footer-container-copyright-container .monlove {
      max-height: 168px;
      max-width: 178px; }
    .footer .footer-container-copyright-container .footer-legal-notes {
      font-family: "Avenir";
      font-size: 8px; }
  @media screen and (max-width: 600px) {
    .footer .footer-container {
      padding: 2px !important; }
    .footer .copyright-container {
      padding-right: 0 !important; }
    .footer .mainbottom-footer {
      padding-left: 0 !important; }
    .footer .mainfooter-container-image {
      padding-left: 0 !important; } }
  @media screen and (max-width: 950px) {
    .footer .footer-container-copyright-container {
      text-align: center; } }
  @media only screen and (min-width: 1023px) and (max-width: 1024px) {
    .footer .mainfooter-container-image {
      padding-left: 58px; }
    .footer .mainbottom-footer {
      padding-left: 125px; }
    .footer .mainbottom-footer .footer-info a {
      font-size: 12.5px; } }
  @media only screen and (min-width: 601px) and (max-width: 1023px) {
    .footer .mainfooter-container-image {
      padding-left: 0; }
    .footer .mainbottom-footer {
      padding-left: 0; } }

.LogoFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33.3%;
  position: relative;
  height: 100%; }
  .LogoFooter .scoobyFooter {
    width: auto;
    padding-top: 5%;
    position: absolute;
    bottom: 0;
    height: 500px; }

@media only screen and (min-width: 1681px) {
  .Home .top {
    background-position: center bottom !important; }
  .Home .schedule-btn {
    padding-right: 16%;
    padding-bottom: 0; } }

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .Home .top {
    background-position: center bottom !important; }
  .Home .logoImg {
    width: 32% !important;
    max-width: 650px;
    padding-right: 24px;
    padding-top: 0.5%; }
  .Home .schedule-btn {
    padding-right: 12%;
    padding-bottom: 0; } }

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .Home .top {
    background-position: center bottom !important; }
  .Home .LogoDiv {
    height: 100vh;
    max-height: 650px;
    justify-content: top !important; }
    .Home .LogoDiv .logoImg {
      width: 32% !important;
      max-width: 650px;
      padding-right: 24px;
      padding-top: 0 !important; }
    .Home .LogoDiv .schedule-btn {
      padding-right: 12%;
      padding-bottom: 2%; } }

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1366px) and (max-height: 1366px) {
  .desktopPageContainer .Home .top {
    height: 45vh !important; } }

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .Home .top {
    height: 100vh !important; }
  .Home .LogoDiv {
    height: 100% !important;
    max-height: 500px !important;
    justify-content: flex-end !important; }
    .Home .LogoDiv .logoImg {
      width: 40%;
      min-width: 450px !important;
      max-width: 500px !important;
      padding-right: 32px !important; }
  .Home .schedule-btn {
    padding-right: 12%;
    padding-bottom: 0; }
  .Home .bottom {
    min-height: 79vh !important; } }

@media only screen and (min-width: 940px) and (max-width: 1023px) {
  .Home .top {
    height: 70vh !important;
    background-position: center bottom !important; }
  .Home .LogoDiv {
    height: 100% !important; }
    .Home .LogoDiv .logoImg {
      min-width: 400px !important;
      max-width: 650px !important;
      padding-top: 0 !important; }
  .Home .schedule-btn {
    padding-right: 12%;
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) and (max-width: 940px) {
  .Home .top {
    height: unset !important; }
  .Home .LogoDiv {
    height: 100% !important;
    justify-content: top !important; }
    .Home .LogoDiv .logoImg {
      min-width: 300px !important;
      max-width: 650px !important;
      padding-top: 0 !important;
      padding-right: 4% !important; }
  .Home .schedule-btn {
    padding-right: 8%;
    padding-bottom: 8%; } }

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .Home .top {
    height: unset !important; }
  .Home .LogoDiv {
    height: 100% !important; }
    .Home .LogoDiv .logoImg {
      min-width: 275px !important;
      max-width: 650px !important;
      padding-top: 0 !important;
      padding-right: 4% !important; }
    .Home .LogoDiv .schedule-btn {
      padding-right: 5%;
      padding-bottom: 10%; }
  .Home .bottom {
    padding-top: 50px !important; }
    .Home .bottom .homeFooter {
      padding-top: 20px !important; } }

@media only screen and (max-width: 600px) {
  .Home .top {
    height: unset !important; }
  .Home .LogoDiv {
    height: 100% !important; }
    .Home .LogoDiv .logoImg {
      width: 42% !important;
      min-width: 150px !important;
      padding-top: 0 !important;
      padding-right: 4% !important; }
    .Home .LogoDiv .schedule-btn {
      padding-right: 6%;
      padding-bottom: 15%; }
  .Home .title1 img {
    max-height: 35px !important; }
  .Home .title3 img {
    width: 80% !important; }
  .Home .banner img {
    width: 100% !important; }
  .container .btn {
    font-size: 20px; }
  .bottom {
    padding-top: 50px !important; }
    .bottom .homeFooter {
      padding-top: 20px !important; } }

@media only screen and (min-width: 600px) {
  .Home .SignUpModule {
    margin-bottom: 5vh !important; } }

@media only screen and (min-width: 361px) and (max-width: 768px) {
  .Home .bottom {
    min-height: 80vh !important; } }

@media only screen and (max-width: 320px) {
  .container .btn {
    font-size: 12px; }
  .schedule-btn {
    padding-right: 14% !important;
    padding-bottom: 19% !important; } }

.Home {
  text-align: center;
  background-image: url(/static/media/home-footer.3ef9776e.jpg);
  background-size: contain;
  width: 100%; }
  .Home .LogoDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100vh; }
    .Home .LogoDiv .logoImg {
      width: 40%;
      max-width: 740px;
      padding-right: 7%;
      padding-top: 5%; }
  .Home .top {
    background-image: url(/static/media/homepage-top.80b419f8.jpg);
    background-size: cover;
    background-position: center bottom;
    height: 100vh; }
  .Home .banner {
    width: 100%;
    background-color: #fdbe0e;
    padding-top: 10px; }
    .Home .banner img {
      width: 100%; }
  .Home .bottom {
    background-image: url(/static/media/homepage-bottom.17474b6a.jpg);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding-top: 65px; }
    .Home .bottom .homeFooter {
      padding-top: 255px; }
  .Home .title1 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Home .title1 img {
      max-height: 40px; }
  .Home .title2 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Home .title2 img {
      width: 90%;
      max-width: 1200px; }
  .Home .title3 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Home .title3 img {
      width: 90%;
      max-width: 1500px; }
  .Home .title4 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Home .title4 img {
      width: 90%;
      margin: 60px 0; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.covid19Button {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #6a3f87;
  color: white; }
  .covid19Button .jss128 {
    width: auto !important; }
  .covid19Button .jss129 {
    display: none !important; }
  .covid19Button .MuiButton-root {
    color: white !important; }

.videoPlayer {
  height: 96vh;
  max-height: 900px; }

@media only screen and (max-width: 600px) {
  .videoPlayer {
    height: 280px; } }

@media only screen and (min-width: 601px) and (max-width: 800px) {
  .videoPlayer {
    height: 400px; } }

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .videoPlayer {
    height: 500px; } }

.linkStyle {
  color: white;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  margin-left: 5px; }
  .linkStyle:hover {
    -webkit-transition: 0.3s all;
    transition: 0.3s all; }

.heading {
  max-width: 130px;
  padding-top: 9px; }

.popupCovid {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  background-color: #6a3f87e6;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
  border: 2px solid #fff;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  overflow-x: hidden;
  font-size: 13px; }
  .popupCovid .MuiButtonBase-root {
    position: absolute;
    color: white;
    right: 1px;
    top: 1px;
    padding: 5px; }
  .popupCovid p {
    padding: 0 10px; }
  .popupCovid .MuiSvgIcon-root {
    width: 2em !important;
    height: 1.5em !important; }

@media only screen and (max-width: 600px) {
  .popupCovid {
    width: 50%;
    z-index: 1; }
  .heading {
    max-width: 85px !important; }
  .popupCovid {
    font-size: 11.5px !important; } }

.SignUpModule {
  display: block;
  color: black;
  min-width: 100%;
  text-align: center; }
  .SignUpModule h2 {
    font-weight: bold;
    font-size: 300%;
    text-shadow: 2px 2px white; }
  .SignUpModule .signupLink {
    text-decoration: none; }
    .SignUpModule .signupLink * {
      font-family: 'Anton', sans-serif; }
  .SignUpModule .btn {
    border: 2px solid white;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    margin: 10px;
    color: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    position: relative;
    overflow: hidden;
    background: #6a3f87;
    width: 40%;
    max-width: 225px;
    text-transform: uppercase; }
  .SignUpModule .btn:hover {
    color: white;
    background: #d5661c;
    cursor: pointer; }
  @media only screen and (max-width: 600px) {
    .SignUpModule .btn {
      width: 40%;
      font-size: 20px;
      white-space: nowrap; } }
  @media only screen and (min-width: 600px) {
    .SignUpModule {
      margin-bottom: 20vh !important; } }

.div_popupCovidForm {
  display: flex;
  justify-content: center; }
  .div_popupCovidForm .popupCovidForm {
    position: fixed;
    bottom: 15%;
    width: 50%;
    background-color: #6a3f87e6;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
    border: 2px solid #fff;
    padding: 4px;
    border-radius: 4px;
    text-align: center;
    overflow-x: hidden;
    font-size: 13px;
    z-index: 99;
    height: 60vh; }
    .div_popupCovidForm .popupCovidForm .heading_covidPopUPForm {
      padding: 30px; }
    .div_popupCovidForm .popupCovidForm .heading_covidPopUPForm span {
      font-size: 25px; }
    .div_popupCovidForm .popupCovidForm .MuiButtonBase-root {
      position: absolute;
      color: white;
      right: 1px;
      top: 1px;
      padding: 5px; }
    .div_popupCovidForm .popupCovidForm .MuiSvgIcon-root {
      width: 2em !important;
      height: 1.5em !important; }
    .div_popupCovidForm .popupCovidForm .for_form {
      width: 70%;
      font-size: 20px;
      margin: 10px; }
    .div_popupCovidForm .popupCovidForm .for_form_dp {
      width: 72%;
      font-size: 21px;
      margin: 10px; }
    .div_popupCovidForm .popupCovidForm .for_social_icon img {
      width: 10%;
      height: 10%;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      padding: 5px; }
    .div_popupCovidForm .popupCovidForm .signUpBtnCF {
      background-color: #6a3f87;
      color: white;
      border-radius: 4px;
      font-size: 40px;
      font-family: 'Anton', sans-serif;
      border: 2px solid white;
      margin-top: 20px;
      width: 31%; }
    .div_popupCovidForm .popupCovidForm .signUpBtnCF:hover {
      cursor: pointer;
      background-color: #D5661C; }

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .div_popupCovidForm .popupCovidForm .heading_covidPopUPForm {
    padding: 58px; }
  .div_popupCovidForm .popupCovidForm {
    height: 50vh;
    bottom: 22%; }
    .div_popupCovidForm .popupCovidForm .signUpBtnCF {
      width: 37% !important;
      font-size: 40px !important; } }

@media only screen and (min-width: 769px) and (max-width: 1022px) {
  .popupCovidForm .signUpBtnCF {
    width: 47% !important;
    font-size: 40px !important; } }

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .popupCovidForm .signUpBtnCF {
    width: 50% !important;
    font-size: 40px !important; } }

@media only screen and (min-width: 601px) and (max-width: 640px) {
  .popupCovidForm .signUpBtnCF {
    width: 60% !important;
    font-size: 40px !important; } }

@media only screen and (min-width: 568px) and (max-width: 600px) {
  .popupCovidForm .signUpBtnCF {
    font-size: 20px !important; } }

@media only screen and (max-width: 567px) {
  .popupCovidForm {
    width: 80% !important; }
    .popupCovidForm .heading_covidPopUPForm {
      padding: 30px; }
    .popupCovidForm .heading_covidPopUPForm span {
      font-size: 25px; }
    .popupCovidForm .signUpBtnCF {
      width: 34% !important;
      font-size: 20px !important; }
  .div_popupCovidForm .popupCovidForm .for_social_icon img {
    width: 13%;
    height: 13%; } }

@media only screen and (max-width: 320px) {
  .popupCovidForm .signUpBtnCF {
    width: 39% !important;
    font-size: 20px !important;
    margin-left: 22px; }
  .popupCovidForm .container .btn {
    font-size: 20px; } }

@media only screen and (min-width: 766px) {
  .contactFormContainer {
    -webkit-text-decoration-color: rgba(128, 0, 128, 0.22);
            text-decoration-color: rgba(128, 0, 128, 0.22);
    text-align: center;
    justify-self: center;
    padding-right: 40px;
    padding-bottom: 315px;
    width: 100%;
    font-family: 'HelveticaUltraCompressed', sans-serif;
    color: indigo; }
    .contactFormContainer input {
      font-family: 'HelveticaUltraCompressed', sans-serif;
      font-size: 25px;
      letter-spacing: .1em;
      padding-left: 20px;
      border-radius: 5px;
      height: 50px;
      width: 500px; }
    .contactFormContainer select {
      font-family: 'HelveticaUltraCompressed', sans-serif;
      font-size: 25px;
      letter-spacing: .1em;
      padding-left: 20px;
      border-radius: 5px;
      height: 40px;
      width: 500px; }
    .contactFormContainer .MuiGrid-container {
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: rgba(152, 102, 27, 0.65);
      width: 650px;
      align-self: center;
      margin-left: 65px; }
    .contactFormContainer p {
      font-size: 25px;
      text-align: center; }
    .contactFormContainer h3 {
      color: indigo;
      font-weight: 500;
      font-size: 25px;
      text-shadow: 0.5px 0.5px #313131;
      text-align: left; }
    .contactFormContainer .topText {
      padding-bottom: 30px;
      padding-left: 30px; }
    .contactFormContainer .friendsText {
      float: left; }
    .contactFormContainer .signUpBtnCF {
      background-color: #6a3f87;
      color: white;
      border-radius: 4px;
      font-size: 25px;
      font-family: 'Anton', sans-serif;
      border: 2px solid white;
      margin-top: 20px;
      padding: 10px 10px; }
      .contactFormContainer .signUpBtnCF * {
        color: white; }
    .contactFormContainer .signUpBtnCF:hover {
      cursor: pointer; } }

@media only screen and (max-width: 500px) {
  .contactFormContainer {
    text-align: center;
    padding-bottom: 100px;
    width: 100%;
    box-sizing: content-box;
    padding-left: 20px;
    padding-right: 20px;
    color: indigo;
    font-family: 'HelveticaUltraCompressed', sans-serif; }
    .contactFormContainer input {
      font-family: 'HelveticaUltraCompressed', sans-serif;
      font-size: 25px;
      letter-spacing: .1em;
      padding-left: 5px;
      border-radius: 5px;
      height: 35px;
      width: 280px; }
    .contactFormContainer select {
      font-family: 'HelveticaUltraCompressed', sans-serif;
      font-size: 25px;
      letter-spacing: .1em;
      padding-left: 20px;
      border-radius: 5px;
      height: 35px;
      width: 250px; }
    .contactFormContainer form {
      height: 100%;
      width: 100%; }
      .contactFormContainer form .MuiGrid-container {
        padding-top: 15px;
        padding-bottom: 20px;
        margin-left: 0px;
        width: 100%;
        background-color: rgba(152, 102, 27, 0.65); }
    .contactFormContainer .topText {
      padding-bottom: 10px; }
    .contactFormContainer h3 {
      color: indigo;
      font-weight: 500;
      text-shadow: 0.5px 0.5px #313131;
      text-align: left; }
    .contactFormContainer .signUpBtnCF {
      background-color: #6a3f87;
      color: white;
      border-radius: 4px;
      font-size: 25px;
      font-family: 'Anton', sans-serif;
      border: 2px solid white;
      margin-top: 20px;
      padding: 10px 10px; }
      .contactFormContainer .signUpBtnCF * {
        color: white; }
    .contactFormContainer .signUpBtnCF:hover {
      cursor: pointer; } }

@media only screen and (min-width: 500px) and (max-width: 766px) {
  .contactFormContainer {
    text-align: center;
    padding-bottom: 100px;
    width: 100%;
    box-sizing: content-box;
    padding-right: 20px;
    color: indigo;
    font-family: 'HelveticaUltraCompressed', sans-serif; }
    .contactFormContainer input {
      font-family: 'HelveticaUltraCompressed', sans-serif;
      font-size: 25px;
      letter-spacing: .1em;
      padding-left: 50px;
      border-radius: 5px;
      height: 40px;
      width: 400px; }
    .contactFormContainer select {
      font-family: 'HelveticaUltraCompressed', sans-serif;
      font-size: 25px;
      letter-spacing: .1em;
      padding-left: 20px;
      border-radius: 5px;
      height: 40px;
      width: 400px; }
    .contactFormContainer .MuiGrid-container {
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: rgba(152, 102, 27, 0.65);
      width: 500px;
      align-self: center;
      margin-left: 35px; }
    .contactFormContainer .topText {
      padding-bottom: 10px; }
    .contactFormContainer p {
      font-size: 18px;
      padding-left: 20px; }
    .contactFormContainer h3 {
      color: indigo;
      font-weight: 500;
      text-shadow: 0.5px 0.5px #313131;
      text-align: left; }
    .contactFormContainer .signUpBtnCF {
      background-color: #6a3f87;
      color: white;
      border-radius: 4px;
      font-size: 25px;
      font-family: 'Anton', sans-serif;
      border: 2px solid white;
      margin-top: 20px;
      padding: 10px 10px; }
      .contactFormContainer .signUpBtnCF * {
        color: white; }
    .contactFormContainer .signUpBtnCF:hover {
      cursor: pointer; } }

@media only screen and (min-width: 769px) {
  .SignUp {
    padding-top: 11%; }
    .SignUp .headerImg {
      width: 18% !important; } }

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .SignUp {
    padding-top: 55px; }
    .SignUp .headerImg {
      width: 30% !important;
      padding-bottom: 20px; } }

@media only screen and (max-width: 600px) {
  .SignUp {
    padding-top: 40px; }
    .SignUp .headerImg {
      width: 37% !important;
      padding-bottom: 20px; } }

.SignUp {
  background-image: url(/static/media/SignUp-BG.17474b6a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .SignUp .hiddenHeader {
    visibility: hidden;
    height: 0px;
    width: 0px;
    margin: 0px;
    padding: 0px; }

.GalleryPage {
  width: 100%;
  background-image: url(/static/media/Gallery_bg.17474b6a.jpg);
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover; }
  .GalleryPage .videoPlayer {
    width: 100%;
    max-height: 750px; }
  .GalleryPage .banner {
    width: 100%;
    margin-top: 15px;
    padding-bottom: 90px; }
  .GalleryPage .imgContainer {
    padding-bottom: 130px; }
  @media only screen and (max-width: 600px) {
    .GalleryPage {
      background-size: cover;
      background-position: center; } }
  .GalleryPage .hidden {
    display: none; }
  .GalleryPage .title {
    margin-top: 2%;
    width: 18%;
    max-width: 430px;
    padding-top: 120px;
    padding-bottom: 40px; }
  @media screen and (max-width: 768px) {
    .GalleryPage .title {
      margin-top: 10px;
      margin-bottom: 16px;
      padding-top: 55px;
      padding-bottom: 30px;
      width: 38%; } }
  .GalleryPage .footer {
    margin-top: 23%; }
  .GalleryPage .imgContainer {
    width: 100%;
    min-height: 1000px;
    max-width: 1100px;
    display: flex;
    flex-direction: column; }
    .GalleryPage .imgContainer img {
      height: 75%;
      width: 75%;
      margin-left: auto;
      margin-right: auto;
      object-fit: cover;
      margin-top: 15px; }
    .GalleryPage .imgContainer .column1 {
      width: 40%; }
      .GalleryPage .imgContainer .column1 > div {
        border: 2px solid rgba(128, 0, 128, 0.22); }
    .GalleryPage .imgContainer .column2 {
      width: 60%; }
      .GalleryPage .imgContainer .column2 > div {
        border: 2px solid rgba(128, 0, 128, 0.22); }
    .GalleryPage .imgContainer .img1 {
      height: 66%;
      width: 100%; }
    .GalleryPage .imgContainer .img2 {
      height: 34%;
      width: 100%; }
    .GalleryPage .imgContainer .img3 {
      height: 34%;
      width: 100%; }
    .GalleryPage .imgContainer .img4 {
      height: 66%;
      width: 100%; }
  .GalleryPage .header-name {
    font-family: 'HelveticaExtraCompressed';
    margin-top: 200px;
    width: 100%;
    color: indigo;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.1cm;
    font-size: 3.8rem;
    font-weight: normal; }
  .GalleryPage .comingSoon {
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 3rem; }
  @media only screen and (max-width: 550px) {
    .GalleryPage .title {
      width: 35%;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 55px;
      padding-bottom: 30px; }
    .GalleryPage .imgContainer {
      flex-direction: column; }
      .GalleryPage .imgContainer img {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px; }
      .GalleryPage .imgContainer .column1, .GalleryPage .imgContainer .column2 {
        width: 100%; }
        .GalleryPage .imgContainer .column1 .img1, .GalleryPage .imgContainer .column1 .img2, .GalleryPage .imgContainer .column1 .img3, .GalleryPage .imgContainer .column1 .img4, .GalleryPage .imgContainer .column2 .img1, .GalleryPage .imgContainer .column2 .img2, .GalleryPage .imgContainer .column2 .img3, .GalleryPage .imgContainer .column2 .img4 {
          height: unset;
          border: none; } }

.NewsPage {
  width: 100%;
  background: url(/static/media/news_bg.17474b6a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .NewsPage .news-container div .bg-img-header {
    width: 30%;
    margin: 20px auto;
    height: auto; }
  .NewsPage .hidden {
    display: none; }
  .NewsPage .footer {
    padding-top: 320px;
    bottom: 0; }
  .NewsPage .news-container {
    padding-top: 10%; }
  .NewsPage .content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    min-height: 125px;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    background-color: rgba(152, 102, 27, 0.65);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    color: white;
    font-family: 'Avenir';
    font-size: 1em; }
  .NewsPage .content:hover {
    background-color: rgba(152, 102, 27, 0.85); }
  .NewsPage .external-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2; }
    .NewsPage .external-link a {
      display: block;
      width: 100%;
      height: 100%; }
  .NewsPage .left-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-left: 10px; }
    .NewsPage .left-content > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .NewsPage .left-content .first-item {
      width: 25%; }
      .NewsPage .left-content .first-item img {
        width: 70%; }
    .NewsPage .left-content .second-item {
      line-height: 0.6cm;
      width: 25%;
      padding-top: 12px;
      padding-bottom: 12px; }
    .NewsPage .left-content .third-item {
      width: 25%;
      padding: 5px; }
      .NewsPage .left-content .third-item img {
        width: 50%; }
    .NewsPage .left-content .forth-item {
      width: 25%; }
  .NewsPage .btn {
    border: 1px solid white;
    padding: 5px 5px;
    border-radius: 4px;
    margin: 10px;
    color: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    position: relative;
    overflow: hidden;
    background: #6a3f87;
    font-family: 'Anton', sans-serif;
    font-size: 30px; }
  .NewsPage .btn:hover {
    color: white;
    background: #d5661c;
    cursor: pointer; }
  @media only screen and (max-width: 600px) {
    .NewsPage .btn {
      font-size: 11px; } }

.faqModule {
  width: 100%;
  background-image: url(/static/media/FAQ-BG.17474b6a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .faqModule .box {
    width: 88%; }
  @media screen and (max-width: 600px) {
    .faqModule .box {
      width: 84%; } }
  .faqModule .faqTitle {
    height: 10%;
    width: 100%; }
    .faqModule .faqTitle .faqTitleImage {
      width: 62%;
      padding-top: 12%; }
    @media screen and (max-width: 600px) {
      .faqModule .faqTitle .faqTitleImage {
        padding-top: 55px;
        width: 100%; } }
    @media screen and (max-width: 768px) {
      .faqModule .faqTitle .faqTitleImage {
        padding-top: 55px;
        width: 95%; } }
    .faqModule .faqTitle .headerBackground {
      background-color: transparent;
      padding-top: 220px;
      width: 100%; }
      .faqModule .faqTitle .headerBackground h1, .faqModule .faqTitle .headerBackground h2 {
        margin: 0; }
      .faqModule .faqTitle .headerBackground .headerTitle {
        font-family: "CocogoosePro-Regular";
        font-size: 5rem;
        color: indigo;
        text-align: center;
        letter-spacing: 0.1cm;
        font-weight: normal; }
  .faqModule .question_container {
    text-align: left;
    padding-bottom: 330px;
    margin-top: 15px; }
    .faqModule .question_container * {
      color: white; }
    .faqModule .question_container .MuiExpansionPanel-rounded {
      background-color: rgba(152, 102, 27, 0.65);
      border-radius: 4px;
      margin-bottom: 4px; }
    .faqModule .question_container .MuiPaper-elevation1 {
      box-shadow: none; }
    .faqModule .question_container .questionBox {
      font-family: "CocogoosePro-Regular";
      font-weight: normal;
      padding-top: 18px;
      padding-bottom: 18px;
      font-size: 24px;
      letter-spacing: .05cm; }
    .faqModule .question_container .answerBox {
      font-family: "CocogoosePro-Regular";
      padding-bottom: 32px;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: .05cm; }
  .faqModule .MuiExpansionPanel-root.Mui-expanded {
    margin: 10px !important; }

.castPage {
  width: 100%;
  min-height: 200vh;
  background-image: url(/static/media/CAST-BG.17474b6a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .castPage .castTitle {
    width: 13%;
    padding-top: 12%; }
  @media screen and (max-width: 768px) {
    .castPage .castTitle {
      padding-top: 55px;
      width: 20%; } }
  @media screen and (min-width: 5119px) {
    .castPage {
      min-height: unset !important;
      height: 100vh; }
      .castPage .castTitle {
        padding-top: 55px;
        width: 10%; } }
  .castPage .castHeader {
    background-color: transparent;
    padding-top: 220px;
    width: 100%; }
    .castPage .castHeader h1 {
      margin: 0; }
  .castPage .cardsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 290px; }
  .castPage .cardContainer {
    margin: 10px 25px !important;
    max-width: 650px;
    background-color: rgba(152, 102, 27, 0.65); }
    .castPage .cardContainer .imageContainer {
      width: 30%;
      max-width: 300px;
      padding-top: 8px;
      padding-bottom: 8px; }
      .castPage .cardContainer .imageContainer .castImage {
        width: 100%;
        height: 237px; }
    .castPage .cardContainer .textContainer {
      width: 65%;
      padding-left: 16px;
      text-align: left; }
      .castPage .cardContainer .textContainer p {
        margin: 0; }
      .castPage .cardContainer .textContainer .textName {
        font-family: "HelveticaExtraCompressed";
        font-size: 32px;
        color: white;
        padding-top: 8px;
        letter-spacing: 3px; }
      .castPage .cardContainer .textContainer .textRole {
        font-family: "HelveticaUltraCompressed";
        font-size: 28px;
        color: indigo;
        letter-spacing: 0.01em; }
      .castPage .cardContainer .textContainer .textDescription {
        font-family: 'Avenir' sans-serif;
        color: white;
        font-size: 16px;
        padding-top: 4px; }
      .castPage .cardContainer .textContainer h2, .castPage .cardContainer .textContainer h3 {
        margin: 0;
        padding-top: 4px; }
      .castPage .cardContainer .textContainer h3 {
        font-size: 1.5em;
        font-weight: 400;
        font-style: italic; }

@media only screen and (max-width: 600px) {
  .castPage {
    width: 100%;
    min-height: 200vh;
    background-image: url(/static/media/CASTCREATIVES-BG.17474b6a.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .castPage .castTitle {
      width: 30%;
      padding-top: 55px; }
    .castPage .cardContainer {
      max-width: 650px;
      background-color: rgba(152, 102, 27, 0.65);
      margin-left: 25px !important;
      align-items: flex-start;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-right: 25px !important; }
      .castPage .cardContainer .imageContainer {
        width: 65%;
        max-width: 200px;
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px; }
        .castPage .cardContainer .imageContainer .castImage {
          width: 100%;
          height: 237px; }
      .castPage .cardContainer .textContainer {
        width: 90%;
        padding-left: 16px;
        text-align: left; }
        .castPage .cardContainer .textContainer p {
          margin: 0; }
        .castPage .cardContainer .textContainer .textName {
          font-family: "HelveticaExtraCompressed";
          font-size: 32px;
          color: white;
          padding-top: 8px;
          letter-spacing: 3px; }
        .castPage .cardContainer .textContainer .textRole {
          font-family: "HelveticaUltraCompressed";
          font-size: 28px;
          color: indigo; }
        .castPage .cardContainer .textContainer .textDescription {
          font-family: "Avenir";
          color: white;
          font-size: 20px;
          padding-top: 4px; } }

.creativePage {
  width: 100%;
  min-height: 200vh;
  background-image: url(/static/media/CAST-BG.17474b6a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .creativePage .creativeTitle {
    width: 22%;
    padding-top: 12%; }
  @media screen and (max-width: 768px) {
    .creativePage .creativeTitle {
      padding-top: 55px;
      width: 48%; } }
  @media screen and (min-width: 5119px) {
    .creativePage {
      min-height: unset !important;
      height: 100vh; }
      .creativePage .creativeTitle {
        padding-top: 55px;
        width: 15%; } }
  .creativePage .creativeHeader {
    background-color: transparent;
    padding-top: 220px;
    width: 100%; }
    .creativePage .creativeHeader h1 {
      margin: 0; }
  .creativePage .cardsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 290px; }
  .creativePage .cardContainer {
    margin: 10px 25px !important;
    max-width: 650px;
    background-color: rgba(152, 102, 27, 0.65); }
    .creativePage .cardContainer .imageContainer {
      width: 30%;
      max-width: 300px;
      padding-top: 8px;
      padding-bottom: 8px; }
      .creativePage .cardContainer .imageContainer .creativeImage {
        width: 100%;
        height: 237px; }
    .creativePage .cardContainer .textContainer {
      width: 65%;
      padding-left: 16px;
      text-align: left; }
      .creativePage .cardContainer .textContainer p {
        margin: 0; }
      .creativePage .cardContainer .textContainer .textName {
        font-family: "HelveticaExtraCompressed";
        font-size: 32px;
        color: white;
        padding-top: 8px;
        letter-spacing: 3px; }
      .creativePage .cardContainer .textContainer .textRole {
        font-family: "HelveticaUltraCompressed";
        font-size: 28px;
        color: indigo;
        letter-spacing: 0.01em; }
      .creativePage .cardContainer .textContainer .textDescription {
        font-family: 'Avenir' sans-serif;
        color: white;
        font-size: 16px;
        padding-top: 4px; }
      .creativePage .cardContainer .textContainer h2, .creativePage .cardContainer .textContainer h3 {
        margin: 0;
        padding-top: 4px; }
      .creativePage .cardContainer .textContainer h3 {
        font-size: 1.5em;
        font-weight: 400;
        font-style: italic; }

@media only screen and (max-width: 600px) {
  .creativePage {
    width: 100%;
    min-height: 200vh;
    background-image: url(/static/media/CASTCREATIVES-BG.17474b6a.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .creativePage .creativeTitle {
      width: 50%;
      padding-top: 55px; }
    .creativePage .cardContainer {
      max-width: 650px;
      background-color: rgba(152, 102, 27, 0.65);
      margin-left: 25px !important;
      align-items: flex-start;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-right: 25px !important; }
      .creativePage .cardContainer .imageContainer {
        width: 65%;
        max-width: 200px;
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px; }
        .creativePage .cardContainer .imageContainer .creativeImage {
          width: 100%;
          height: 237px; }
      .creativePage .cardContainer .textContainer {
        width: 90%;
        padding-left: 16px;
        text-align: left; }
        .creativePage .cardContainer .textContainer p {
          margin: 0; }
        .creativePage .cardContainer .textContainer .textName {
          font-family: "HelveticaExtraCompressed";
          font-size: 32px;
          color: white;
          padding-top: 8px;
          letter-spacing: 3px; }
        .creativePage .cardContainer .textContainer .textRole {
          font-family: "HelveticaUltraCompressed";
          font-size: 28px;
          color: indigo; }
        .creativePage .cardContainer .textContainer .textDescription {
          font-family: "Avenir";
          color: white;
          font-size: 20px;
          padding-top: 4px; } }

.legalPage {
  background-image: url(/static/media/Gallery_bg.17474b6a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%; }
  .legalPage .headerImg {
    width: 30%; }
  .legalPage .legalText {
    padding-top: 200px;
    padding-bottom: 150px; }
    .legalPage .legalText .privacyTitle {
      font-family: "HelveticaUltraCompressed"; }
    .legalPage .legalText .legalParagraph {
      padding-left: 100px;
      padding-right: 100px;
      padding-bottom: 145px;
      text-align: left; }
      .legalPage .legalText .legalParagraph p a {
        color: #6a3f87; }

@media only screen and (max-width: 812px) {
  .legalPage .legalText {
    padding-top: 55px;
    padding-bottom: 50px; }
  .headerImg {
    width: 60% !important; } }

@media only screen and (max-width: 600px) {
  .legalPage .legalParagraph {
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: left !important; } }

.touPage {
  background-image: url(/static/media/Gallery_bg.17474b6a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%; }
  .touPage .headerImg {
    width: 30%; }
  .touPage .touText {
    padding-top: 200px;
    padding-bottom: 150px; }
    .touPage .touText .touTitle {
      font-family: "HelveticaUltraCompressed"; }
    .touPage .touText .touParagraph {
      padding-left: 100px;
      padding-right: 100px;
      padding-bottom: 30px;
      text-align: left; }
      .touPage .touText .touParagraph p a {
        color: #6a3f87; }
  .touPage .footer {
    padding-top: 175px !important; }

@media only screen and (max-width: 812px) {
  .touPage .touText {
    padding-top: 55px;
    padding-bottom: 50px; }
  .headerImg {
    width: 60% !important; } }

@media only screen and (max-width: 600px) {
  .touPage .touParagraph {
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: left !important; } }

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .vipText {
    padding-top: 55px; }
    .vipText .headerImg {
      width: 60%;
      margin: 20px; }
    .vipText .vipContainer {
      padding-left: 50px;
      padding-right: 50px; }
      .vipText .vipContainer .vipPreParty {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%; }
      .vipText .vipContainer .vipPackage {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%; } }

@media only screen and (max-width: 600px) {
  .vipText {
    padding-top: 5%; }
    .vipText .headerImg {
      width: 70%;
      padding-bottom: 5%;
      padding-top: 55px; }
    .vipText .vipContainer {
      padding-left: 50px;
      padding-right: 50px; }
      .vipText .vipContainer .vipPreParty {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%; }
      .vipText .vipContainer .vipPackage {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%; } }

@media only screen and (min-width: 800px) {
  .vipText {
    padding-top: 12%; }
    .vipText .headerImg {
      width: 30%;
      margin: 10px;
      margin-top: 0 !important; }
    .vipText .vipContainer {
      padding-left: 15%;
      padding-right: 15%; }
      .vipText .vipContainer .vipPreParty {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%; }
      .vipText .vipContainer .vipPackage {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%; } }

.vipPage {
  background-image: url(/static/media/Gallery_bg.17474b6a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%; }
  .vipPage .vipText .vipContainer {
    text-decoration: none;
    font-family: 'CocogoosePro-Regular' sans-serif;
    font-size: 15px;
    color: #361e45;
    padding-bottom: 310px; }
    .vipPage .vipText .vipContainer li {
      text-align: left; }
    .vipPage .vipText .vipContainer .vipPreParty {
      background-color: rgba(152, 102, 27, 0.65);
      margin-bottom: 20px; }
    .vipPage .vipText .vipContainer .vipPackage {
      background-color: rgba(152, 102, 27, 0.65); }

.faqModule .cityDatePanelTicketButton {
  text-align: center;
  border: 2px solid white;
  border-radius: 4px;
  margin: 5px;
  color: #fff;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  position: relative;
  background: #6a3f87;
  font-family: 'Anton', sans-serif;
  font-size: 24px;
  white-space: nowrap; }

.faqModule .cityDatePanelTicketButton:hover {
  color: white;
  background: #d5661c !important;
  cursor: pointer; }

.faqModule .MuiButton-label:hover {
  color: white !important; }

.faqModule .ques_container {
  margin-left: 14%;
  margin-right: 14%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(152, 102, 27, 0.65);
  color: #361e45;
  padding: 20px 10%; }
  @media screen and (max-width: 940px) {
    .faqModule .ques_container {
      margin-left: 5%;
      margin-right: 5%;
      padding: 20px 5%; } }

.faqModule .ques_container p {
  font-family: Avenir; }

.faqModule .heading {
  margin-top: 12%;
  margin-bottom: 25px;
  max-width: 40%; }
  @media screen and (max-width: 768px) {
    .faqModule .heading {
      margin-bottom: 10px;
      margin-top: 55px;
      max-width: 80%; } }
  @media screen and (max-width: 600px) {
    .faqModule .heading {
      max-width: 85% !important; }
      .faqModule .heading .second {
        width: 52%; } }

.faqModule .heading.second {
  margin-top: 50px;
  width: 28%; }
  @media screen and (max-width: 600px) {
    .faqModule .heading.second {
      width: 52%; } }
  @media screen and (max-width: 768px) {
    .faqModule .heading.second {
      margin-bottom: 10px;
      margin-top: 30px;
      width: 55%; } }

.faqModule .MuiTypography-root:not(:last-child) {
  margin-bottom: 10px; }

.faqModule .adjust a {
  color: blue; }

@media screen and (max-width: 500px) {
  .faqModule .seperator {
    display: none; }
  .faqModule .adjust {
    display: block; } }

.faqModule .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 500px) {
    .faqModule .wrap {
      display: block; } }

.faqModule .desc {
  text-align: left;
  margin-right: 15px; }
  .faqModule .desc p {
    margin: 0;
    margin-bottom: 5px; }
  @media screen and (max-width: 500px) {
    .faqModule .desc {
      margin-right: 0; } }

.faqModule p, .faqModule span {
  font-size: 20px; }

.MusicPage {
  width: 100%;
  background: url(/static/media/news_bg.17474b6a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .MusicPage .title {
    width: 30%;
    margin: 10px auto;
    height: auto;
    padding-top: 10%; }
  .MusicPage .musicText {
    font-family: 'CocogoosePro-Regular';
    color: indigo;
    text-align: center; }
  .MusicPage .albumWidth {
    max-width: 70%; }
  .MusicPage .listenText {
    font-family: 'CocogoosePro-Regular';
    color: indigo;
    text-align: center;
    font-size: 2.5em;
    padding-top: 15px; }
  .MusicPage .hidden {
    display: none; }
  .MusicPage .for-newvideo {
    width: 100%;
    padding: 10px;
    max-height: 750px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden; }
  .MusicPage .for-video {
    width: 50%;
    float: left;
    padding: 10px;
    max-height: 750px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden; }
  .MusicPage .for-listen {
    width: 50%;
    float: left;
    padding: 10px;
    max-height: 750px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden; }
    .MusicPage .for-listen .container .btn {
      width: 50% !important; }
  .MusicPage .footer {
    bottom: 0;
    padding-top: 320px; }
  @media only screen and (max-width: 1024px) {
    .MusicPage .listen-div {
      padding-right: 15px; }
      .MusicPage .listen-div .for-listen .container .btn {
        width: 100% !important; } }
  @media only screen and (max-width: 960px) {
    .MusicPage .title {
      width: 60%;
      padding-top: 55px; } }
  @media only screen and (max-width: 600px) {
    .MusicPage .albumWidth {
      max-width: 100%; }
    .MusicPage .for-video {
      width: 100%; } }

.Tickets {
  text-align: center; }

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-size: cover;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed; }

@font-face {
  font-family: "Avenir";
  src: url(/static/media/AvenirLTStd-Black.d74d87dd.woff) format("woff"); }

@font-face {
  font-family: "CoCogoose";
  src: url(/static/media/CocogoosePro-Regular.03085fa6.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaBold";
  src: url(/static/media/Helvetica-Bold.8137f279.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaExtraCompressed";
  src: url(/static/media/Helvetica-ExtraCompressed.89cbc751.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaNeueLTStdBlkCn";
  src: url(/static/media/HelveticaNeueLTStd-BlkCn.18927f67.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaUltraCompressed";
  src: url(/static/media/HelveticaUltraCompressed.35d4fdb5.ttf) format("truetype"); }

@font-face {
  font-family: "NeulandInline";
  src: url(/static/media/Neuland-Inline.97fc38a6.ttf) format("truetype"); }

.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  -webkit-animation: 1s fadeIn;
          animation: 1s fadeIn;
  font-family: Avenir; }
  .App .desktopPageContainer {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    width: 100%;
    margin: 0; }
  .App h1 {
    font-size: 3.5em; }

.loadingContainer {
  height: 100vh;
  width: 100vw;
  opacity: 1;
  position: fixed;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.fadeIn {
  -webkit-animation: 1s fadeIn;
          animation: 1s fadeIn;
  opacity: 0; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.loading {
  height: 40px;
  display: flex;
  align-items: center; }

.loadingImage {
  width: 50%;
  max-width: 400px; }

.obj {
  width: 6px;
  height: 40px;
  background: purple;
  margin: 0 3px;
  border-radius: 10px;
  -webkit-animation: loading 0.8s infinite;
          animation: loading 0.8s infinite; }

.obj:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

.obj:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.obj:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

.obj:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

.obj:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.obj:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

.obj:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s; }

@-webkit-keyframes loading {
  0% {
    height: 0; }
  50% {
    height: 40px; }
  100% {
    height: 0; } }

@keyframes loading {
  0% {
    height: 0; }
  50% {
    height: 40px; }
  100% {
    height: 0; } }

.hidden {
  display: none !important; }

.bg-theme .MuiPaper-root {
  border: 2px solid white;
  padding: 5px 5px;
  border-radius: 4px;
  margin: 10px;
  color: #fff;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
  background: #6a3f87;
  font-family: 'Anton', sans-serif; }

.bg-theme .MuiSvgIcon-root {
  fill: white; }

.bg-theme * {
  font-family: Avenir !important; }

