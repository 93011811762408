.NewsPage {
  width: 100%;
  background: url('../../assets/compressedimages/news_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .news-container div .bg-img-header {
    // max-width: 350px;
    width: 30%;
    margin: 20px auto;
    height: auto;
  }

  .hidden {
    display: none;
  }

  .footer {
    padding-top: 320px;
    bottom: 0;
  }

  .news-container {
    padding-top: 10%;
  }

  .content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    min-height: 125px;
    max-height: fit-content;
    background-color: rgba(152, 102, 27, 0.65);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    color: white;
    font-family: 'Avenir';
    font-size: 1em;
  }

  .content:hover {
    background-color: rgba(152, 102, 27, 0.85);
  }

  .external-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

  }

  .left-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-left: 10px;

    >div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .first-item {
      img {
        width: 70%;
      }

      width: 25%;
    }

    .second-item {
      line-height: 0.6cm;
      width: 25%;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .third-item {
      img {
        width: 50%;
      }

      width: 25%;
      padding: 5px;
    }

    .forth-item {
      width: 25%;
    }
  }

  .btn {
    border: 1px solid white;
    padding: 5px 5px;
    border-radius: 4px;
    margin: 10px;
    color: #fff;
    transition: 0.8s;
    position: relative;
    overflow: hidden;
    background: rgba(106, 63, 135, 1);
    font-family: 'Anton', sans-serif;
    font-size: 30px;
  }

  .btn:hover {
    color: white;
    background: rgb(213, 102, 28);
    cursor: pointer;
  }

  // .right-content {
  //   width: 50%;
  //   img {
  //     max-height: 100px;
  //     margin-right: 20px;
  //     object-fit: contain;
  //   }
  //   display:flex;
  //   justify-content: flex-end;
  //   align-content: center;
  //   align-items: center;
  //   flex-direction: row;

  //   .snippet {
  //     padding-right: 24px;
  //     text-align: left;
  //     flex: 1;
  //   }
  // }

  // @media only screen and (min-width: 1023px) and (max-width: 1024px) {

  //   .left-content .second-item {
  //     margin: 0 12px;
  //   }

  //   .left-content .second-item img:last-child {
  //     height: 40px;
  //   }

  // }

  // @media only screen and (min-width: 601px) and (max-width: 1023px) {

  //   .left-content .second-item {
  //     margin: 0 12px;
  //   }

  //   .left-content .second-item img:last-child {
  //     height: 50px;
  //   }

  // }

  // @media only screen and (max-width: 768px) {
  //   .news-container div .bg-img-header {
  //     width: 60%;
  //   }

  //   .news-container {
  //     padding-top: 55px;
  //   }
  // }

  @media only screen and (max-width: 600px) {

    .btn {
      font-size: 11px;
    }

    // .content {
    //   font-size: 0.9em;
    //   max-height: fit-content;
    // }

    //   .content:hover {
    //     background-color: none;
    //   }

    //   .news-content-container {
    //     text-align: center;
    //     width: 100%;
    //   }

    //   .left-content {
    //     width: 70%;
    //     justify-content: space-between;

    //     .second-item {
    //       img {
    //         width: 100%;
    //       }
    //     }
    //   }

    //   .right-content {
    //     width: 30%;

    //     img {
    //       margin: 0;
    //       max-width: 80%;
    //       max-height: 120px;
    //     }

    //     .snippet {
    //       display: none !important;
    //     }
    //   }


  }

  // @media only screen and (min-width: 601px) and (max-width: 1023px) and (orientation: landscape) {

  //   .left-content .first-item {
  //     line-height: unset;
  //   }

  //   .content {
  //     font-size: 0.8em;
  //   }

  //   .left-content .second-item img:last-child {
  //     height: 35px;
  //   }
  // }
}

// @media only screen and (max-width: 600px) {
//   #root > div > div.mobilePageContainer > div > div.news-container > div:nth-child(4) > div > div.left-content > div.second-item > img{
//     width: 100%;
//   }

//   #root > div > div.mobilePageContainer > div > div.news-container > div:nth-child(10) > div > div.left-content > div.second-item > img{
//     width: 100%;
//   }

//   #root > div > div.mobilePageContainer > div > div.news-container > div:nth-child(11) > div > div.left-content > div.second-item > img{
//     width: 100%;
//   }
// }

// #root > div > div.desktopPageContainer > div > div.news-container > div:nth-child(4) > div > div.left-content > div.second-item > img{
//   width: 100%;
// }
// #root > div > div.desktopPageContainer > div > div.news-container > div:nth-child(10) > div > div.left-content > div.second-item > img{
//   width: 100%;
// }


// #root > div > div.desktopPageContainer > div > div.news-container > div:nth-child(11) > div > div.left-content > div.second-item > img{
//  width: 100%;
// }