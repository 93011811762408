@media only screen and (min-width: 1681px) {
  .Home {
    .top {
      background-position: center bottom !important;
    }

    .schedule-btn{
      padding-right: 16%;
      padding-bottom: 0;
    }
    
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .Home{
    .top {
      background-position: center bottom !important;
    }

    .logoImg{
      width: 32%!important;
      max-width: 650px;
      padding-right: 24px;
      padding-top: 0.5%;
    }

    .schedule-btn{
      padding-right: 12%;
      padding-bottom: 0;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .Home{
    .top {
      background-position: center bottom !important;
    }

    .LogoDiv{
      height: 100vh;
      max-height: 650px;
      justify-content: top!important;

      .logoImg{
        width: 32%!important;
        max-width: 650px;
        padding-right: 24px;
        padding-top: 0!important;
      }

      .schedule-btn{
        padding-right: 12%;
        padding-bottom: 2%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1366px) and (max-height: 1366px) {
  .desktopPageContainer .Home{
    .top {
      height: 45vh!important;
    }     
    
  }
}


@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .Home{
    .top {
      height: 100vh !important;
    }
    
    .LogoDiv{
      height: 100% !important;
      max-height: 500px !important;
      justify-content: flex-end!important;
      .logoImg{
        width: 40%;
        min-width: 450px !important;
        max-width: 500px !important;
        padding-right: 32px !important;
      }
    }

    .schedule-btn{
      padding-right: 12%;
      padding-bottom: 0;
    }

    .bottom{
      min-height: 79vh!important;
    }
  }
}

@media only screen and (min-width: 940px) and (max-width: 1023px) {
  .Home{
    .top {
      height: 70vh !important;
      background-position: center bottom !important;
    }

    .LogoDiv{
      height: 100% !important;
      .logoImg{
        min-width: 400px !important;
        max-width: 650px !important;
        padding-top: 0 !important;
      }
    }
    .schedule-btn{
      padding-right: 12%;
      padding-bottom: 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 940px) {
  .Home{
    .top {
      height: unset !important;
    }

    .LogoDiv{
      height: 100% !important;
      justify-content: top!important;

      .logoImg{
        min-width: 300px !important;
        max-width: 650px !important;
        padding-top: 0 !important;
        padding-right: 4% !important;
        // padding-bottom: 5% !important;
      }
    }
    .schedule-btn{
      padding-right: 8%;
      padding-bottom: 8%;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .Home{
    .top {
      height: unset !important;
    }

    .LogoDiv{
      height: 100% !important;

      .logoImg{
        min-width: 275px !important;
        max-width: 650px !important;
        padding-top: 0 !important;
        padding-right: 4% !important;
        // padding-bottom: 5% !important;
      }

      .schedule-btn{
        padding-right: 5%;
        padding-bottom: 10%;
      }
    }

    // .title4{
    //   margin: 30px 30px!important;
    // }

    .bottom {
      padding-top: 50px!important;
  
      .homeFooter{
        padding-top: 20px!important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Home {
    .top {
      height: unset !important;
    }
    

    .LogoDiv{
      height: 100% !important;
      .logoImg{
        width: 42% !important;
        min-width: 150px !important;
        padding-top: 0 !important;
        padding-right: 4% !important;
        // padding-bottom: 12% !important;
      }

      .schedule-btn{
        padding-right: 6%;
        padding-bottom: 15%;
      }
    }

    .title1 {
      img {
        max-height: 35px !important;
      }
    }

    .title3 {
      img {
        width: 80% !important;
      }
    }

    .banner{
      img{
        width: 100% !important;
      }
    }
  }

  // .title4{
  //   margin: 30px 17px!important;
  // }

  .container .btn{
    font-size: 20px;
  }

  .bottom {
    padding-top: 50px!important;

    .homeFooter{
      padding-top: 20px!important;
    }
  }
}

@media only screen and (min-width: 600px){
  .Home{
    .SignUpModule {
      margin-bottom: 5vh!important;
    }
  }
}

@media only screen and (min-width: 361px) and (max-width: 768px){
  .Home {
    .bottom {
      min-height: 80vh!important;
    }
  }

}

@media only screen and (max-width: 320px) {
  .container .btn{
    font-size: 12px;
  }

  .schedule-btn{
    padding-right: 14% !important;
    padding-bottom: 19% !important;
  }
}

.Home {
  text-align: center;
  background-image: url('../../assets/compressedimages/home-footer.jpg');
  background-size: contain;
  width: 100%;

  .LogoDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100vh;
    // max-height: 750px;

    .logoImg{
      width: 40%;
      // min-width: 500px;
      max-width: 740px;
      padding-right: 7%;
      padding-top: 5%;
    }
  }


  .top {
    background-image: url('../../assets/compressedimages/homepage-top.jpg');
    background-size: cover;
    background-position: center bottom;
    height: 100vh;
  }

  .banner{
    width: 100%;
    background-color: #fdbe0e;
    padding-top: 10px;
    // background-image: url('../../assets/compressedimages/homepage-bottom.jpg');
    // background-size: cover;
    // background-position: center;
    // min-height: fit-content;
    // padding-top: 25px;

    img{
      width: 100%;
    }
  }

  .bottom {
    background-image: url('../../assets/compressedimages/homepage-bottom.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding-top: 65px;

    .homeFooter{
      padding-top: 255px;
    }
  }

  .title1 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 40px;
    }
  }

  .title2 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 90%;
      max-width: 1200px;
    }
  }

  .title3 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 90%;
      max-width: 1500px;
    }
  }

  .title4 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 60px;
    img {
      width: 90%;
      margin: 60px 0;
    }
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.covid19Button{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #6a3f87;
  color: white;

  .jss128{
    width: auto!important;
  }
  
  .jss129 {
    display: none!important;
  }
  .MuiButton-root{
    color: white!important;
  }
}

