.MusicPage {
    width: 100%;
    background: url('../../assets/compressedimages/news_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    .title {
      // max-width: 350px;
      width: 30%;
      margin: 10px auto;
      height: auto;
      padding-top: 10%;
    }
  
    .musicText{
      font-family: 'CocogoosePro-Regular';
      color: indigo;
      text-align: center;
    }

    .albumWidth{
      max-width: 70%;
    }

    .listenText{
      font-family: 'CocogoosePro-Regular';
      color: indigo;
      text-align: center;
      font-size: 2.5em;
      padding-top: 15px;
    }

    .hidden {
      display: none;
    }

    .for-newvideo {
      width: 100%;
      // float: left;
      padding: 10px;
      max-height: 750px;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
    } 

    .for-video {
      width: 50%;
      float: left;
      padding: 10px;
      max-height: 750px;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
    } 

    .for-listen {
        width: 50%;
        float: left;
        padding: 10px;
        max-height: 750px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;

        .container .btn{
            width: 50%!important;
        }
      } 
    
    .footer {
      bottom: 0;
      padding-top: 320px;
    }

    @media only screen and (max-width: 1024px) {

        .listen-div{
            padding-right: 15px;
            .for-listen {
                .container .btn{
                    width: 100%!important;
                }
            }       
        }
    }

    @media only screen and (max-width: 960px) {
        .title {
          width: 60%;
          padding-top: 55px;
      }
    }

    @media only screen and (max-width: 600px) {
      
        .albumWidth{
          max-width: 100%;
        }

        .for-video {
            width: 100%;
        }       
    }
}
  
   