.legalPage {
    background-image: url('../../assets/compressedimages/Gallery_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    .headerImg{
        width: 30%;
    }
    .legalText {
        padding-top: 200px;
        padding-bottom: 150px;
        .privacyTitle {
            font-family: "HelveticaUltraCompressed";
        }
        .legalParagraph {
            padding-left: 100px;
            padding-right: 100px;
            padding-bottom: 145px;
            text-align: left;

            p a{
                color: #6a3f87;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .legalPage {
        .legalText {
            padding-top: 55px;
            padding-bottom: 50px;
        }
      }
      .headerImg{
          width: 60%!important;
      }
}

@media only screen and (max-width: 600px) {
    .legalPage {    
        .legalParagraph {
            padding-left: 20px!important;
            padding-right: 20px!important;
            text-align: left!important;
        }   
    }
}