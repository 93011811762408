.GalleryPage {
  width: 100%;
  background-image: url('../../assets/compressedimages/Gallery_bg.jpg');
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;

  .videoPlayer {
    width: 100%;
    max-height: 750px;
  }

  .banner {
    width: 100%;
    margin-top: 15px;
    padding-bottom: 90px;
  }

  .imgContainer {
    padding-bottom: 130px;
  }

  @media only screen and (max-width: 600px) {
    background-size: cover;
    background-position: center;
  }

  .hidden {
    display: none;
  }

  .title {
    margin-top: 2%;
    width: 18%;
    max-width: 430px;
    padding-top: 120px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    .title {
      margin-top: 10px;
      margin-bottom: 16px;
      padding-top: 55px;
      padding-bottom: 30px;
      width: 38%;
    }
  }
  .footer {
    margin-top: 23%;
  }

  .imgContainer {
    width: 100%;
    min-height: 1000px;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
   
    img {
      height: 75%;
      width: 75%;
      margin-left: auto;
      margin-right: auto;
      object-fit: cover;
      margin-top: 15px;
    }
  
    .column1 {
      > div {
        border: 2px solid rgba(128,0,128,0.22);
      }
      width: 40%;
    }
    .column2 {
      > div {
        border: 2px solid rgba(128,0,128,0.22);
      }
      width: 60%;
    }

    .img1 {
      height: 66%;
      width: 100%;
    }
    
    .img2 {
      height: 34%;
      width: 100%;
    }
    .img3 {
      height: 34%;
      width: 100%;
    }
    .img4 {
      height: 66%;
      width: 100%;
    }
    
  }

  .header-name {
    font-family: 'HelveticaExtraCompressed';
    margin-top: 200px;
    width: 100%;
    color: indigo;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.1cm;
    font-size: 3.8rem;
    font-weight: normal;
  }

  .comingSoon {
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 3rem;
  }

  @media only screen and (max-width: 550px) {

    .title {
      width: 35%;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 55px;
      padding-bottom: 30px;
    }

    .imgContainer {
      flex-direction: column;
      img {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
      }
      .column1, .column2 {
        width: 100%;
        .img1, .img2, .img3, .img4 {
          height: unset;
          border: none;
        }
      }
    }
    
  }
}