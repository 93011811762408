@media only screen and (min-width: 680px) {
  .headerLinks {
    position: absolute;
    float: right;
    top: 45px;
    right: 24px;
  }
}

@media only screen and (max-width:1130px) {
  .desktopHeader {
    .logo {
      max-width: 240px !important;
    }
  }
}

.desktopHeader {
  position: absolute;
  z-index: 1;
  min-width: 100%;
  height: 170px;

  .logo {
    max-width: 260px;

    a {
      position: block;
      width: 100%;
      height: 100%;
    }

    img {
      padding-top: 12px;
      padding-left: 12px;
      width: 100%;
    }
  }

  @media only screen and (min-width:938px) and (max-width:1040px) {
    .btn {
      font-size: 32px !important;
    }

    .logo {
      max-width: 200px !important;
    }
  }

  @media only screen and (min-width:680px) and (max-width:938px) {
    .logo {
      padding-top: 12px;
      padding-left: 12px;
      max-width: 160px !important;
    }
  }
  
  .headerLinks {
      a {
      padding-right: 12px;
      padding-left: 12px;
      color: rgba(54, 30, 69, 1);
      text-decoration: none;
      font-weight: 500;
      font-family: "CocogoosePro-Regular";
      font-size: 1em;
      text-transform: uppercase;
    }
    a:hover {
      color: rgb(213, 102, 28);
    }
  }
}

.mobileHeader {
  min-width: 100%;
  background-color: rgba(106, 63, 135, 1);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;

  a {
    text-decoration: none;
    font-family: "CocogoosePro-Regular";
  }

  .container{
    text-align: center;
  
    .btn{
        font-size: 1em !important;
    }
  }
}

.burgerMenu {
  .menuButton {
    color: white;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    position: fixed;
    right: 4px;
    top: 4px;
    z-index: 9999999;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded{
  width: 40%;
  background-color: rgba(106, 63, 135, 1);
  top: 53px !important;   
  left: unset!important;
  right: 10px;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button{
  justify-content: center;
  background-color: rgba(106, 63, 135, 1);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a:hover {
    color: black;
  }
}
.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button :hover{
  text-align: center;
  height: 100%;
  background-color: rgb(87, 43, 117);
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiMenu-paper {
  width: 100%;
  text-align: center;
  a {
    font-weight: 500;
    font-family: "CocogoosePro-Regular";
    font-size: 1em;
    text-decoration:  none;
    color: white;
  }
}

.MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

@media only screen and (max-width:812px){
  .MuiMenu-paper a{
    width: 100%;
    text-align: center;
  }

  .MuiList-root .MuiListItem-root{
    width: unset!important;
  } 
}
