.linkStyle {
    color: white;
    transition: 0.3s all;
    margin-left: 5px;

    &:hover {
        transition: 0.3s all;
    }
}

.heading {
    max-width: 130px;
    padding-top: 9px;
}

.popupCovid{
    position: fixed;
    bottom:0;
    right:0;
    width: 30%;
    background-color: #6a3f87e6;
    color: white;
    height: fit-content;
    overflow: auto;
    border: 2px solid #fff;
    padding: 5px;
    border-radius: 4px;
    text-align: center;
    overflow-x: hidden;
    font-size: 13px;

    .MuiButtonBase-root{
        position: absolute;
        color: white;       
        right: 1px;
        top: 1px;
        padding: 5px;
    }

    p{
        padding: 0 10px;
    }

    .MuiSvgIcon-root{
        width: 2em!important;
        height: 1.5em!important;
    }
    
}

@media only screen and (max-width: 600px) {
    .popupCovid{
        width: 50%;
        z-index: 1;
    }

    .heading{
        max-width: 85px!important;
    }

    .popupCovid{
        font-size: 11.5px!important;
    }
}